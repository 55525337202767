/* eslint-disable prefer-regex-literals */
export const courseDiscountTypes = {
  AVOIDANCE: 'AVOIDANCE',
  DEFENSIVE: 'DEFENSIVE',
  INEXPERIENCED: 'INEXPERIENCED',
  PREVENTION: 'PREVENTION',
  SENIOR: 'SENIOR',
  MILITARY_DEFENSIVE: 'MILITARY_DEFENSIVE',
  NATIONAL_GUARD: 'NATIONAL_GUARD'
};

export const bixStates = {
  AR: true,
  AZ: true,
  DC: true,
  FL: true,
  GA: true,
  IA: true,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MD: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  TX: true,
  UT: true,
  WI: true,
  WV: true
};

export const originalGSNICStates = ['AZ', 'IL', 'OH', 'MO', 'TX'];

// Turn on FL when adding 'FL' to branchLiveStates if we don't want to get online quotes.
export const preventQuoteOnlineStates = { FL: false };

export const branchLiveStates = [
  'AR',
  'AZ',
  'DC',
  'GA',
  'IA',
  'IL',
  'IN',
  'KY',
  'LA',
  'MD',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NE',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'TX',
  'UT',
  'WI',
  'WV'
];

export const reopeningStates = ['AZ', 'AR', 'IA', 'IL', 'IN', 'KY', 'MO', 'MT', 'NE', 'NM', 'OH', 'OR', 'UT', 'WI'];

export const branchPlusCarriers = ['loggerhead', 'orchid', 'annex'];
export const branchPlusPolicyTypes = [
  'home',
  'renters',
  'condo',
  'personal umbrella',
  'home and personal umbrella',
  'renters and personal Umbrella',
  'condo and personal umbrella'
];
export const statesEligibleForBranchPlusMPD = {
  FL: { A: true }
};

export const noConnectedHomeProviderSpecialOfferByState = { MD: ['SimpliSafe'] };
export const connectedHomeMonitoredNCustomExperienceStates = ['IL'];
export const noTrailerCoverageStates = ['MA', 'NJ', 'NY', 'VA'];

export const shortRateFeeStates = {};

export const checkCarRecallsStates = Object.keys(bixStates).filter(
  (state: string) => bixStates[state as keyof typeof bixStates]
);

/**
 * Valid Zip Codes can be represented as either a single value, or "tuples" containing the min & max values of a range.
 * Values are inclusive.
 */
export const validZipCodeRanges: { [index: string]: [[number, number]] } = {
  AL: [[35000, 36999]],
  AR: [[71601, 72999]],
  AZ: [[85001, 86556]],
  CO: [[80001, 82000]],
  DC: [[20001, 20899]],
  FL: [[32000, 34999]],
  GA: [[30000, 39999]],
  IA: [[50001, 52999]],
  ID: [[83200, 84000]],
  IL: [[60001, 62999]],
  IN: [[46001, 47997]],
  KS: [[66000, 69999]],
  KY: [[40003, 42999]],
  LA: [[70000, 71999]],
  MA: [[1001, 5599]],
  MD: [[20331, 21999]],
  ME: [[3901, 4992]],
  MI: [[48001, 49999]],
  MO: [[63001, 65899]],
  MS: [[38601, 39999]],
  MT: [[59001, 59999]],
  ND: [[58000, 58999]],
  NE: [[68001, 69999]],
  NH: [[3000, 3900]],
  NM: [[87001, 89999]],
  OH: [[43001, 45999]],
  OK: [[73001, 74999]],
  OR: [[97000, 97999]],
  PA: [[15001, 19999]],
  SC: [[29001, 29999]],
  SD: [[57001, 57999]],
  TN: [[37000, 39999]],
  TX: [[73301, 88589]],
  UT: [[84000, 84999]],
  VA: [[20000, 24999]],
  VT: [[5000, 5999]],
  WI: [[53001, 55000]],
  WV: [[24701, 26999]],
  WY: [[82001, 83199]]
};

export const umUimCoverageArray = {
  AK: ['UM', 'UIM'],
  AL: ['UM'],
  AR: ['UMBI', 'UIM'],
  AZ: ['UM_UIM'],
  CO: ['UM_UIM'],
  DC: ['UMBI', 'UIMBI', 'UIM', 'UM'],
  FL: ['UM'],
  GA: ['UM'],
  IA: ['UM', 'UIM'],
  ID: ['UM', 'UIM'],
  IL: ['UMUIM', 'UM_UIM'],
  IN: ['UM_UIM'],
  KS: ['UMUIM'],
  KY: ['UM', 'UIM'],
  LA: ['UMBI'],
  MA: ['UM', 'UIM'],
  MD: ['UM', 'EUIM'],
  ME: ['UMUIM'],
  MO: ['UM', 'UIM'],
  MI: ['UMUIM'],
  MS: ['UM', 'UIM'],
  MT: ['UM', 'UIM'],
  ND: ['UM', 'UIM'],
  NE: ['UM', 'UIM'],
  NH: ['UM', 'UIM'],
  NM: ['UMUIM'],
  OH: ['UM_UIM'],
  OK: ['UM'],
  OR: ['UM'],
  PA: ['UM', 'UIM'],
  SC: ['UM', 'UIM'],
  SD: ['UM', 'UIM'],
  TN: ['UM'],
  TX: ['UMUIMBI', 'UMUIMPD'],
  UT: ['UM', 'UIM'],
  VA: ['UMUIM'],
  VT: ['UM', 'UIM'],
  WI: ['UM', 'UIM'],
  WV: ['UMBI', 'UIMBI'],
  WY: ['UM']
};

export const disableExcludeDriverStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KS: true,
  KY: true,
  LA: true,
  MA: true,
  ME: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NH: true,
  NE: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: true,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: true,
  WY: true
};

export const noUmbrellaStates = { KS: true, MA: true, WV: true };
export const umbrellaStartDateStates = {
  GA: '2023-06-01',
  MT: '2022-11-20'
};

export const myCommunityDiscountStateCaps = {
  AR: 0,
  AZ: 25,
  IA: 0,
  ID: 0,
  IL: 0,
  IN: 0,
  KY: 0,
  LA: 0,
  MO: 50,
  MS: 0,
  MT: 0,
  NH: 0,
  NM: 0,
  OH: 0,
  SD: 0,
  TX: 0,
  VT: 0,
  WV: 0
};

export const myCommunityDiscountVerbalReferralStates = ['IA', 'NM', 'NE', 'OH'];

export const alabamaLogicInstallmentFeeStates = {
  AL: true
};

export const fixedHomeInstallmentFeeStates = {
  LA: true
};

export const courseDiscountTypeByStates = {
  AL: courseDiscountTypes.SENIOR,
  AR: courseDiscountTypes.SENIOR,
  AZ: false,
  CO: courseDiscountTypes.SENIOR,
  DC: courseDiscountTypes.SENIOR,
  FL: courseDiscountTypes.DEFENSIVE,
  GA: false,
  IA: false,
  ID: courseDiscountTypes.SENIOR,
  IL: courseDiscountTypes.SENIOR,
  IN: false,
  KS: courseDiscountTypes.AVOIDANCE,
  KY: courseDiscountTypes.SENIOR,
  LA: false,
  MA: courseDiscountTypes.INEXPERIENCED,
  ME: courseDiscountTypes.SENIOR,
  MI: false,
  MO: false,
  MS: courseDiscountTypes.SENIOR,
  MT: courseDiscountTypes.SENIOR,
  ND: false,
  NH: false,
  NE: false,
  NM: courseDiscountTypes.SENIOR,
  OH: courseDiscountTypes.SENIOR,
  OK: courseDiscountTypes.DEFENSIVE,
  OR: courseDiscountTypes.SENIOR,
  PA: courseDiscountTypes.DEFENSIVE,
  SC: courseDiscountTypes.DEFENSIVE,
  SD: courseDiscountTypes.SENIOR,
  TN: courseDiscountTypes.SENIOR,
  TX: false,
  UT: courseDiscountTypes.SENIOR,
  VT: false,
  WI: false,
  WV: courseDiscountTypes.SENIOR,
  WY: courseDiscountTypes.SENIOR
};

export const additionalDriverDiscountsTypesByStates = {
  // this allows for only one additional discount, will need refactor if we end up with states where driver can have 3+ discounts offered
  KY: {
    condition: 'IS_ACTIVE_DUTY',
    discountType: courseDiscountTypes.MILITARY_DEFENSIVE
  },
  MT: {
    condition: 'IS_ACTIVE_DUTY_NG',
    discountType: courseDiscountTypes.NATIONAL_GUARD
  }
};

export const stateSpecificToolTips = {
  KY: 'discountCourseKY',
  MA: 'inexperiencedCourseMA'
};

export const seniorDiscountByAgeOnlyStates = {
  FL: 55,
  KY: 55,
  MI: 65
};

export const lapsedLicenseMonthsStates = {
  MA: 12
};

export const courseDiscountsAges = {
  AL: 55,
  AR: 55,
  CO: 55,
  DC: 50,
  FL: 55,
  ID: 55,
  IL: 60,
  KS: 1,
  KY: 55,
  MA: 7, // To be used as: age - ageFirstLicensed < courseDiscountsAges[state]
  ME: 55,
  MS: 55,
  MT: 55,
  NM: 55,
  OH: 60,
  OK: 1,
  OR: 55,
  PA: 55,
  SC: 1,
  SD: 55,
  TN: 55,
  UT: 55,
  VA: 60,
  WV: 55,
  WY: 55
};

export const zendeskInfoByStateWithKeys = {
  KY: {
    IS_ACTIVE_DUTY:
      'has taken a voluntary accident prevention course provided by the Armed Forces in the last 5 years.',
    SENIOR:
      'has taken an Accident Prevention Course approved by the Transportation Cabinet of Kentucky within the last 5 years.'
  },
  MA: {
    [courseDiscountTypes.INEXPERIENCED]: `has taken a Driver Training Course in the last ${courseDiscountsAges.MA} years`
  },
  MT: {
    IS_ACTIVE_DUTY_NG: 'has taken a voluntary, state-approved defensive driving course.',
    SENIOR: 'has taken a state-approved defensive driving course.'
  }
};

export const directAffinityCodes = ['KST1', 'SMAFIN1', 'QWZ1'];
export const partnerMonolineRejectionAffinityCodes = ['ORL1', 'ORL2', 'ORL3', 'ORL4', 'ORLE'];

export const hurricaneDeductibleStates = {
  AZ: false,
  CO: false,
  IL: false,
  IN: false,
  KS: false,
  LA: true,
  MA: true,
  MO: false,
  OH: false,
  TX: false
};

export const sR22States = { NH: true };

export const mandatoryWindHailDeductible = {};
export const limitedBranchWindhailCoverage = { AL: true, GA: true };

export const homeownersRights = {
  AL: 'https://www.aldoi.gov/PDF/Consumers/HomeownersBillofRightsAct.pdf'
};

/** States that, when they have a minimum % deductible, only allow % deductibles */
export const percentageWindHailDeductible = {};
export const umpdWithCollisionStates = ['ID', 'IL', 'IN', 'OR'];
export const noUDRsAllowedStates = ['IN', 'MD'];
export const allowBindUDRStates = ['KY', 'ME', 'MD', 'MI', 'NH'];

export const limitedCollisionStates = { MA: true };

export const minimumDeductibleCollision = 500;
export const minimumDeductibleComprehensive = 500;

export const UMBIEqualsBIStates = {
  NH: true
};

export const UIMBIEqualsUMBIUnlessRejectStates = {
  AR: true,
  MA: true,
  MT: true
};

export const combinedUMUIM = {
  AL: true,
  AR: false,
  AZ: false,
  CO: true,
  DC: false,
  FL: true,
  GA: true,
  IA: true,
  ID: false,
  IL: true,
  IN: true,
  KS: true,
  LA: true,
  MA: false,
  MD: true,
  ME: true,
  MI: true,
  MO: false,
  MS: true,
  MT: false,
  ND: true,
  NE: true,
  NH: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  SD: true,
  TN: true,
  TX: true,
  UT: false,
  VA: true,
  VT: true,
  WI: false,
  WV: false,
  WY: true
};

export const policyLevelUMPDStates = {
  AR: true,
  DC: true,
  GA: true,
  IN: true,
  MD: true,
  MS: true,
  SC: true,
  TN: true,
  TX: true,
  VA: true,
  VT: true,
  WV: true,
  WY: true
};
export const policyLevelUMPDInUMSelectionStates = {
  DC: true,
  SC: true,
  WV: true
};

export const allow50100UMBIon2550BIStates = {
  VT: true
};

export const UMPDOnlyWithUMBIStates = {
  AR: true,
  GA: true,
  ID: true,
  UT: true,
  MD: true,
  TN: true,
  TX: true,
  WY: true
};
export const noUMPDStates = {
  AL: true,
  AZ: true,
  FL: true,
  IA: true,
  KS: true,
  MA: true,
  KY: true,
  ME: true,
  MI: true,
  MT: true,
  ND: true,
  NE: true,
  NH: true,
  PA: true,
  SD: true,
  WY: true
};

export const noMedPayStates = { AR: true, DC: true, MI: true, MD: true, ND: true, OR: true, UT: true };

export const doNotShowCarLevelUMPDStates = {
  ...policyLevelUMPDStates,
  ...noUMPDStates
};

export const enhancedRoofWindstormStates = { SC: true, LA: true };

/**
 *
 * @description states with coverageRSExtended always set to true
 */
export const coverageRSExtendedForcedStates = {
  DC: true,
  ME: true,
  MT: true,
  NH: true,
  SD: true,
  VT: true,
  WV: true
};

/**
 *
 * @description states with coverageRSExtended always set to false, basic coverage regardless of roof age
 */
export const coverageRSForcedStates = {
  OK: true,
  TX: true,
  ND: true
};

/**
 *
 * @description states with coverageRSExtended selector disabled
 */
export const coverageRSEDisabledStates = {
  AR: true
};

// When adding a new state to windstormMitigationDiscountStates, also add the options to windstormMitigationLevelValues.json
export const windstormMitigationDiscountStates = { AL: true, MS: true };
export const windstormMitigationDiscountYearBuilt = 2006;
export const windstormMitigationDefinitionsLinkStates = {
  MS: 'https://app.getguru.com/card/ijAxGaRT/Mississippi-Wind-Mitigation-Discount-Definitions'
};

export const pullAutoDataOnlyForAutoPolicyStates = {
  AK: true,
  DC: true,
  GA: true,
  MA: true,
  ND: true,
  NH: true,
  SC: true,
  VT: true
};

/** CCW violation type */
export const CCWStates = { AL: true };

export const altCreditAlgorithmStates = {
  KS: true,
  FL: true,
  SC: true
};
export const noCreditStates = {
  MA: true
};

export const flatInstallmentFee = 3;

export const noPPCExclusionStates = { KY: true, MI: true, NH: true };

export const soldToEffectiveDaysIntervalByState = {
  AZ: 1,
  AR: 1,
  IA: 1,
  IL: 1,
  IN: 1,
  KY: 1,
  MO: 1,
  MT: 1,
  NE: 1,
  NM: 1,
  OH: 1,
  OR: 1,
  UT: 1,
  WI: 1
};

export const waterBackupNameByState = {
  MD: 'Sewer Backup'
};

// these are the states in which we want to force pulling MVRs before binding
// This constant is also used for preBindCredit data pulls on co applicants
export const preBindMVRStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'NM',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const driverCarAssignmentStates = {
  MA: true,
  VA: true,
  VT: true
};

export const minLimitRentalStates = {
  AL: 40,
  AR: 40,
  AZ: 40,
  CO: 40,
  DC: 40,
  GA: 40,
  IA: 40,
  ID: 40,
  IL: 40,
  IN: 40,
  KS: 40,
  KY: 40,
  LA: 40,
  MA: 40,
  MD: 40,
  ME: 40,
  MI: 40,
  MO: 40,
  MS: 40,
  MT: 40,
  ND: 40,
  NE: 40,
  NH: 40,
  NM: 40,
  OH: 40,
  OK: 40,
  OR: 40,
  PA: 40,
  SC: 40,
  SD: 40,
  TN: 40,
  TX: 40,
  UT: 40,
  VA: 600,
  VT: 40,
  WI: 40,
  WV: 40,
  WY: 40
};

export const allowInstallPayOnHomePoliciesStates = {
  AR: true,
  AZ: true,
  IA: true,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MO: true,
  MT: true,
  NE: true,
  NM: true,
  OH: true,
  OR: true,
  UT: true,
  WI: true
};

export const onlyPayInFullOnAutoPoliciesStates = {
  MD: true,
  PA: true
};

export const basementTypes = {
  Slab: 'S',
  Raised: 'R',
  Unfinished: 'U',
  PartialFinished: 'PF',
  FullFinished: 'FF'
};

export const uninsuredMotoristDefaultStates = {
  VT: '1000000'
};

export const canRejectPIPStates = ['KY'];

export const pipDefaultStates = {
  AR: 'NONE',
  DC: 'NONE',
  FL: '0 NI',
  KS: '4500',
  KY: '10000/0DED',
  MA: '250HH',
  ND: '30',
  OR: 'NONE'
};

export const statesWithNonStandardPIPWLValues = ['FL'];

export const leaseLoanStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  FL: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: false },
  IN: { isBix: true, isNotBix: true },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: false, isNotBix: false },
  MA: { isBix: true, isNotBix: true },
  MD: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OK: { isBix: true, isNotBix: true },
  OR: { isBix: true, isNotBix: true },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const advancedConnectedHomeStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: false },
  IN: { isBix: true, isNotBix: false },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: true, isNotBix: true },
  MA: { isBix: true, isNotBix: true },
  MD: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OK: { isBix: true, isNotBix: false },
  OR: { isBix: true, isNotBix: true },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const discountInventoryScoreStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: true },
  IN: { isBix: true, isNotBix: false },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: true, isNotBix: true },
  MA: { isBix: false, isNotBix: false },
  MD: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OR: { isBix: true, isNotBix: true },
  OK: { isBix: true, isNotBix: false },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const discountPaperlessStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: false },
  IN: { isBix: true, isNotBix: false },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: true, isNotBix: true },
  MA: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MD: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OK: { isBix: true, isNotBix: false },
  OR: { isBix: true, isNotBix: true },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const pipStates = {
  AR: true,
  DC: false,
  FL: true,
  KS: true,
  KY: true,
  MA: true,
  MD: true,
  ND: true,
  OR: true,
  TX: true,
  UT: true
};

export const accidentalDeathStates = {
  PA: true,
  SD: true
};

export const coverageRSLabelStates = {
  DC: 'Roof Surfaces',
  ME: 'Roof Surfaces',
  NH: 'Roof Surfaces',
  ND: 'Roof Surfaces',
  SD: 'Roof Surfaces',
  VT: 'Roof Surfaces'
};

/*
    07/05/23 - Disable the Roof Surface endorsement for the following:
    roofType: max age limit
  */
export const extendedCoverageRSLimits = {
  W: 10,
  C: 15,
  M: 20,
  S: 20,
  T: 20
};

/**
 *
 * @description states that will not calculate roof age limit depending on its material
 */
export const extendedCoverageRSByRoofMaterialStateExclusions = ['AR', 'MA', 'MD', 'ME', 'NH', 'VA', 'VT'];

/**
 *
 * @description states that will not have their roof extended coverage expired
 */
export const extendedCoverageRSExpirationByAgeStateExclusions = {
  DC: true,
  PA: true,
  ND: true,
  NM: true,
  MD: true,
  WV: true
};

export const coverageRSDescriptionStates = {
  DC: 'Coverage for roof surfaces up to repair/replacement cost',
  ME: 'Coverage for roof surfaces up to repair/replacement cost. If you do not purchase this, roof claims will be handled with a payment schedule.',
  NH: 'Coverage for roof surfaces up to repair/replacement cost',
  ND: 'Coverage for roof surfaces up to repair/replacement cost',
  SD: 'Coverage for roof surfaces up to repair/replacement cost. If you do not purchase this, roof claims will be handled with a payment schedule.',
  VT: 'Coverage for roof surfaces up to repair/replacement cost'
};

// policy-level income loss here only:
export const incomeLossStates = {
  DC: true,
  PA: true,
  SD: true,
  VA: true
};

export const UMPDMustBePDOrLessStates = ['GA', 'MS'];

export const changeRejectionLangToNeedToTalkStates = { NH: true, FL: true };
export const changeRejectionLangToNeedToTalkPhoneNumberStates = { FL: '8442276528' };

export const communityDriveAvailableStates = {
  AL: { showForBix: true, showForNonBix: true },
  AR: { showForBix: true, showForNonBix: true },
  AZ: { showForBix: true, showForNonBix: false },
  CO: { showForBix: true, showForNonBix: true },
  DC: { showForBix: true, showForNonBix: true },
  GA: { showForBix: true, showForNonBix: true },
  IA: { showForBix: true, showForNonBix: true },
  ID: { showForBix: true, showForNonBix: true },
  IL: { showForBix: true, showForNonBix: false },
  IN: { showForBix: true, showForNonBix: true },
  KS: { showForBix: true, showForNonBix: true },
  KY: { showForBix: true, showForNonBix: true },
  LA: { showForBix: true, showForNonBix: true },
  MA: { showForBix: true, showForNonBix: true },
  MD: { showForBix: true, showForNonBix: true },
  ME: { showForBix: true, showForNonBix: true },
  MI: { showForBix: true, showForNonBix: true },
  MO: { showForBix: true, showForNonBix: false },
  MS: { showForBix: true, showForNonBix: true },
  MT: { showForBix: true, showForNonBix: true },
  ND: { showForBix: true, showForNonBix: true },
  NE: { showForBix: true, showForNonBix: true },
  NH: { showForBix: true, showForNonBix: true },
  NM: { showForBix: true, showForNonBix: true },
  OH: { showForBix: true, showForNonBix: false },
  OK: { showForBix: true, showForNonBix: true },
  OR: { showForBix: true, showForNonBix: true },
  PA: { showForBix: true, showForNonBix: true },
  SC: { showForBix: true, showForNonBix: true },
  SD: { showForBix: true, showForNonBix: true },
  TN: { showForBix: true, showForNonBix: true },
  TX: { showForBix: true, showForNonBix: false },
  UT: { showForBix: true, showForNonBix: true },
  VA: { showForBix: false, showForNonBix: false },
  VT: { showForBix: true, showForNonBix: true },
  WI: { showForBix: true, showForNonBix: true },
  WV: { showForBix: true, showForNonBix: true },
  WY: { showForBix: true, showForNonBix: true }
};

export const errorCodes = {
  CHAT: 4000,
  EMAIL: 4001,
  POWER: 4002,
  NOTLIVE: 4003,
  NOT_ENOUGH_INFO: 4004,
  INVALIDINPUT: 4005,
  NEEDPRIORADDRESS: 5001,
  NEEDDATEOFBIRTH: 5002,
  NEEDCV: 5003,
  NEED360: 5004,
  NEEDCAR: 5005,
  INVALIDCAR: 5006,
  INVALIDQUOTE: 5007,
  INVALIDSTATE: 5008,
  WONTWRITECAR: 5009,
  INVALIDVIN: 5010,
  MORATORIUM: 5011,
  NEEDDRIVERSLICENSE: 5012,
  NOPOBOXADDRESS: 5013,
  MIN_PREMIUM: 5014,
  PRIMARYUDRNODRIVERSLICENSE: 5015,
  NEEDPERSONINFO: 5016,
  RESTRICTEDSTATE: 5017,
  SETFEES: 5018,
  SUSPICIOUSQUOTE: 5019,
  BLANKQUOTE: 5020,
  QUOTELIMITEXCEEDED: 5021,
  SECOND_TIME_PRIOR_CANCEL_NON_PAY: 5022,
  MISSING_LAT_LNG: 5023,
  INVALID_ZIP_CODE: 5024,
  NEEDAPTUNIT: 5025,
  CANNOTQUOTEONLINE: 5026,
  NBZIP: 5027,
  NOT_ALLOWED_TO_QUOTE_IN_STATE: 5028,
  RESTRICTED_STATE_FOR_DIRECT: 5029,
  OLD_OFFER: 5030,
  RIDESHARING_RESTRICTION: 5031,
  UNABLE_TO_BUNDLE: 5032,
  API_TRIED_TO_RECALCULATE_OFFER_WITH_NO_OPTIONS: 5033,
  AUX_RATING: 5034,
  RATE_LIMIT_QUOTE: 5035,
  AFFINITY_DISABLED: 5036,
  UNKNOWN: 0,
  MSRP_TOO_HIGH: 5037
};

export const errorClassificationLookup = {
  4000: 'BLOCK',
  4001: 'BLOCK',
  4002: 'BLOCK',
  4003: 'BLOCK',
  4004: 'BLOCK',
  4005: 'BLOCK',
  50001: 'BYPASS_ALERT',
  50006: 'BYPASS_ALERT',
  5001: 'ALERT',
  5002: 'ALERT',
  5003: 'ALERT',
  5004: 'ALERT',
  5005: 'ALERT',
  5006: 'ALERT',
  5008: 'BLOCK',
  50096: 'DISMISSIBLE_REJECTION',
  50097: 'DISMISSIBLE_REJECTION',
  50098: 'DISMISSIBLE_REJECTION',
  5009: 'ALERT',
  5010: 'ALERT',
  5011: 'BLOCK',
  5012: 'ALERT',
  5013: 'ALERT',
  5014: 'BLOCK',
  5015: 'ALERT',
  5016: 'ALERT',
  5017: 'BLOCK',
  5018: 'BLOCK',
  5019: 'BLOCK',
  5020: 'BLOCK',
  5021: 'BLOCK',
  5022: 'BLOCK',
  5023: 'ALERT',
  5024: 'ALERT',
  5025: 'ALERT',
  5026: 'BLOCK',
  5027: 'BLOCK',
  5028: 'BLOCK',
  5029: 'BLOCK',
  5030: 'BLOCK',
  5031: 'BLOCK',
  5032: 'BLOCK',
  5033: 'BLOCK',
  5035: 'BLOCK'
};

export const rejectCodes = {
  // all codes from 10000 - 49999 are FCRA
  VERISK_GENERIC: 10000,
  VERISK_PROPERTY_CLAIMS_TOTAL: 10009,
  VERISK_PROPERTY_CLAIMS_SAME_PERIL: 10010,
  VERISK_PROPERTY_CLAIMS_OLDER_HOME: 10011,
  VERISK_PROPERTY_CLAIMS_BITE: 10012,
  VERISK_AUTO_CLAIMS_SUS: 10013,
  VERISK_AUTO_CLAIMS_DIS: 10014,
  VERISK_AUTO_CLAIMS_POLICY: 10015,
  VERISK_AUTO_CLAIMS_DRIVER: 10016,
  VERISK_PIP_CLAIMS: 10017,
  VERISK_AUTO_CLAIMS_FEL: 10018,
  VERISK_RENTERS_HOME_CLAIMS_THREE_YEARS: 10019,
  VERISK_RENTERS_HOME_WATER_LOSSES: 10020,
  VERISK_PROPERTY_CLAIMS_WATER_LOSSES: 10021,

  TU_DRIVERRISK_GENERIC: 20000,
  TU_DRIVERRISK_SUS: 20001,
  TU_DRIVERRISK_DIS: 20002,
  TU_DRIVERRISK_POLICY: 20003,
  TU_DRIVERRISK_DRIVER: 20004,
  TU_DRIVERRISK_FEL: 20005,

  // Non-FCRA rejections:
  NON_FCRA_GENERIC: 50000,
  NO_CARS: 50001,
  SQFT_HIGH: 50002,
  OCCUPANTS_HIGH: 50003,
  CAR_RATIO: 50004,
  YEARBUILT_OLD: 50005,
  VERISK_NO360: 50006,
  NO_PRIOR_AUTO_INSURANCE: 50007,
  VERISK_360_EIFS: 50008,
  VERISK_360_STILTS: 50009,
  VERISK_360_WOOD: 50010,
  VERISK_360_RCE_LOW: 50011,
  VERISK_360_RCE_HIGH: 50012,
  VERISK_PPC_10: 50013,
  VERISK_NO_PPC: 50014,
  TOO_MANY_DRIVERS: 50015,
  TOO_MANY_CARS: 50016,
  TEST_CASE: 50017,
  INELIGIBLE_COASTAL_LOCATION: 50018,
  VERISK_NO_FIRELINE: 50019,
  VERISK_FIRELINE_TOO_HIGH: 50020,
  VERISK_FIRELINE_PLUS_FPC_TOO_HIGH: 50021,
  NO_DTC: 50022,
  INELIGIBLE_DTC: 50023,
  SPP_OVER_10PCT_COVC: 50024,
  SPP_OVER_10PCT_COVA: 50025,
  SPP_TOTAL_OVER_50PCT_COVC: 50026,
  NO_HOME_WIND_HAIL_EXCLUSION: 50027,
  VERISK_PPC_TOO_HIGH: 50028,
  DID_NOT_ASK_FOR_AUTO: 50029,
  CAPE_ROOF_CONDITION: 50030,
  CAPE_YARD_DEBRIS: 50031,
  COASTAL_COUNTY: 50032,
  LESS_THAN_1YEAR_PC: 50033,
  INVALID_PRIOR_CARRIER: 50034,
  ANY_INCIDENTS_OR_VIOLATIONS: 50035,
  MORE_CARS_THAN_DRIVERS: 50036,
  BI_TOO_LOW: 50037,
  CV_NO_HIT: 50038,
  SOME_DRIVERS_EXCLUDED: 50039,
  INSURANCE_SCORE_LOW: 50040,
  SELF_REPORTED_HOME_UW_DISQUALIFIER: 50041,
  SELF_REPORTED_AUTO_UW_DISQUALIFIER: 50042,
  CONDO_RENTED_OUT_RESTRICTION: 50083,
  NO_MONOLINE_HOME: 50084,
  NO_MONOLINE_CONDO: 50085,
  NO_MONOLINE_AUTO: 50086,
  PRIMARY_AGE_RESTRICTION: 50087,
  SECONDARY_HOME: 50088,
  HOME_MUST_BE_CLEAN: 50089,
  VERISK_360_RCE_PLUS_IBSC_HIGH: 50094,
  RISK_CONCENTRATION: 50095,
  PROPERTY_MISMATCH: 50096,
  NO_COMMERCIAL_PROPERTIES: 50097,
  UNSUPPORTED_PROPERTY: 50098,

  // API rejections
  API_HOME_FAILED_IN_HA_BUNDLE: 50090,
  API_AUTO_FAILED_IN_HA_BUNDLE: 50091,
  API_CONDO_FAILED_IN_CA_BUNDLE: 50092,
  API_AUTO_FAILED_IN_CA_BUNDLE: 50093,

  // Restarting numbering of new codes
  HAS_AUX_RATING: 50100,
  GREATER_THAN_MINIMUM_ACRES: 50101,
  HOME_CHARACTERISTIC_ASBESTOS: 50102,
  HISTORIC_HOME: 50103,
  BRANDED_TITLE: 50104,
  VACANT_HOME: 50105,
  UNSUPPORTED_ROOF_MATERIAL: 50106,
  FORECLOSURE_HIT: 50107,
  POLICY_TYPE_BLOCKED_FOR_AFFINITY_IN_THIS_STATE: 50108,

  // Florida Rejections

  // Home
  // If any is added, remember to add it also in "floridaHomeSoftRejectCodes" or "floridaHomeHardRejectCodes"
  // The ones without FL_ prefix are country wide
  FL_WATER_HEATER_AGE_GREATER_THAN_15_YEARS: 50043,
  FL_COV_A_LESS_THAN_100000: 50044,
  FL_TRAMPOLINES_PRESENT: 50045,
  FL_GREATER_THAN_5_ACRES_OR_PC_9_OR_10: 50046,
  FL_MOBILE_PREFABRICATED_HOMES_LOG_OR_UNCONVENTIONAL_CONSTRUCTION: 50047,
  FL_HOMES_WITH_INELIGIBLE_DOG_BREED_OR_BITE_AGGRESSION_HISTORY: 50048,
  FL_PRIOR_SINKHOLE_ACTIVITY_OR_ADJACENT_TO_KNOWN_SINKHOLES: 50049,
  FL_SADDLE_ANIMALS_FARM_OR_NON_DOMESTICATED_ANIMALS: 50050,
  FL_OPEN_FOUNDATIONS: 50051,
  FL_PROPERTY_IS_IN_FORECLOSURE: 50052,
  FL_POLYBUTYLENE_PLUMBING: 50053,
  FL_SKATEBOARD_RAMP_DIVING_BOARD_BIKE_RAMP_POOL_SLIDE: 50054,
  FL_PROPERTY_OWNED_BY_CORP_LLC_LP_LLP_ESTATE_OR_PARTNERSHIP: 50055,
  FL_EXCLUDE_CONTENT_COVERAGE: 50056,
  FL_RUN_DAY_CARE_OUT_OF_MY_HOME: 50057,
  FL_SEASONAL_OR_SECONDARY_HOME: 50058,
  FL_MORE_THAN_2_MORTGAGES: 50059,
  FL_COMMERCIAL_OCCUPATION: 50060,
  FL_HOME_GREATER_THAN_40_YEARS: 50061,
  FL_SHINGLE_OR_WOOD_ROOF: 50062,
  FL_INELIGIBLE_OCCUPANCY: 50063,
  FL_FLAT_ROOFS: 50064,
  FL_TILE_OR_METAL_ROOFS_GREATER_THAN_25: 50065,
  FL_PRIOR_LOSS_IN_5_YEARS: 50066,
  FL_TWO_PROPERTY_OR_LIABILITY_LOSSES: 50067,
  FL_NO_PRIOR_INSURANCE: 50068,
  FL_PRIOR_POLICY_WAS_CANCELLED_OR_NON_RENEWED: 50069,
  FL_COV_A_GREATER_THAN_950K: 50070,
  FL_REQUESTED_SINKHOLE_COVERAGE: 50071,
  FL_UNCONVENTIONAL_CONSTRUCTION_OR_DEFECTIVE_MATERIALS: 50072,
  FL_NO_HOME_WIND_HAIL_EXCLUSION: 50073,

  // Auto
  // If any is added, remember to add it also in "floridaAutoSoftRejectCodes" or "floridaAutoHardRejectCodes"
  // The ones without FL_ prefix are country wide
  FL_EXCLUDE_BI_COVERAGE: 50074,
  FL_LAPSE_GREATER_THAN_2_DAYS: 50075,
  FL_MORE_THAN_1_PIP_CLAIM_PAST_3_YEARS: 50076,
  FL_MORE_THAN_1_AT_FAULT_ACCIDENT_PAST_3_YEARS: 50077,
  FL_MORE_THAN_2_ACCIDENTS_REGARDLESS_OF_FAULT_PAST_3_YEARS: 50078,
  FL_MORE_THAN_2_INFRACTIONS_PAST_3_YEARS: 50079,
  FL_INSURANCE_SCORE_IS_NO_HIT_OR_NO_SCORE: 50080,
  FL_MVR_IS_A_NO_HIT_OR_UDR: 50081,
  FL_NEED_SR22: 50082
};

export const floridaHomeSoftRejectCodes = [
  rejectCodes.FL_WATER_HEATER_AGE_GREATER_THAN_15_YEARS,
  rejectCodes.FL_COV_A_LESS_THAN_100000,
  rejectCodes.FL_GREATER_THAN_5_ACRES_OR_PC_9_OR_10,
  rejectCodes.FL_RUN_DAY_CARE_OUT_OF_MY_HOME,
  rejectCodes.FL_HOME_GREATER_THAN_40_YEARS,
  rejectCodes.FL_SHINGLE_OR_WOOD_ROOF,
  rejectCodes.FL_FLAT_ROOFS,
  rejectCodes.FL_PRIOR_LOSS_IN_5_YEARS,
  rejectCodes.FL_NO_PRIOR_INSURANCE,
  rejectCodes.FL_PRIOR_POLICY_WAS_CANCELLED_OR_NON_RENEWED,
  rejectCodes.FL_REQUESTED_SINKHOLE_COVERAGE,
  rejectCodes.FL_COV_A_GREATER_THAN_950K,
  rejectCodes.FL_EXCLUDE_CONTENT_COVERAGE,
  rejectCodes.FL_NO_HOME_WIND_HAIL_EXCLUSION,
  rejectCodes.FL_SEASONAL_OR_SECONDARY_HOME
];

export const floridaHomeHardRejectCodes = [
  rejectCodes.FL_TRAMPOLINES_PRESENT,
  rejectCodes.FL_MOBILE_PREFABRICATED_HOMES_LOG_OR_UNCONVENTIONAL_CONSTRUCTION,
  rejectCodes.FL_HOMES_WITH_INELIGIBLE_DOG_BREED_OR_BITE_AGGRESSION_HISTORY,
  rejectCodes.FL_PRIOR_SINKHOLE_ACTIVITY_OR_ADJACENT_TO_KNOWN_SINKHOLES,
  rejectCodes.FL_SADDLE_ANIMALS_FARM_OR_NON_DOMESTICATED_ANIMALS,
  rejectCodes.FL_OPEN_FOUNDATIONS,
  rejectCodes.FL_PROPERTY_IS_IN_FORECLOSURE,
  rejectCodes.FL_SKATEBOARD_RAMP_DIVING_BOARD_BIKE_RAMP_POOL_SLIDE,
  rejectCodes.FL_PROPERTY_OWNED_BY_CORP_LLC_LP_LLP_ESTATE_OR_PARTNERSHIP,
  rejectCodes.FL_POLYBUTYLENE_PLUMBING,
  rejectCodes.FL_MORE_THAN_2_MORTGAGES,
  rejectCodes.FL_TILE_OR_METAL_ROOFS_GREATER_THAN_25,
  rejectCodes.FL_TWO_PROPERTY_OR_LIABILITY_LOSSES,
  rejectCodes.FL_INELIGIBLE_OCCUPANCY,
  rejectCodes.FL_UNCONVENTIONAL_CONSTRUCTION_OR_DEFECTIVE_MATERIALS,
  rejectCodes.FL_COMMERCIAL_OCCUPATION,
  rejectCodes.INELIGIBLE_DTC
];

export const floridaAutoSoftRejectCodes = [
  rejectCodes.FL_EXCLUDE_BI_COVERAGE,
  rejectCodes.FL_LAPSE_GREATER_THAN_2_DAYS,
  rejectCodes.FL_INSURANCE_SCORE_IS_NO_HIT_OR_NO_SCORE,
  rejectCodes.FL_MVR_IS_A_NO_HIT_OR_UDR
];

export const floridaAutoHardRejectCodes = [
  rejectCodes.FL_NEED_SR22,
  rejectCodes.FL_MORE_THAN_1_PIP_CLAIM_PAST_3_YEARS,
  rejectCodes.FL_MORE_THAN_1_AT_FAULT_ACCIDENT_PAST_3_YEARS,
  rejectCodes.FL_MORE_THAN_2_ACCIDENTS_REGARDLESS_OF_FAULT_PAST_3_YEARS,
  rejectCodes.FL_MORE_THAN_2_INFRACTIONS_PAST_3_YEARS,
  rejectCodes.INELIGIBLE_DTC,
  rejectCodes.INELIGIBLE_COASTAL_LOCATION
];

export const monolineAutoRejectCodes = [
  rejectCodes.LESS_THAN_1YEAR_PC,
  rejectCodes.CV_NO_HIT,
  rejectCodes.INVALID_PRIOR_CARRIER,
  rejectCodes.ANY_INCIDENTS_OR_VIOLATIONS,
  rejectCodes.MORE_CARS_THAN_DRIVERS,
  rejectCodes.BI_TOO_LOW,
  rejectCodes.SOME_DRIVERS_EXCLUDED,
  rejectCodes.INSURANCE_SCORE_LOW
];

// Specific set of Reject Codes that are set somewhere but need to be cleared in Staff
export const rejectCodesToBeClearedInStaff = [rejectCodes.NO_HOME_WIND_HAIL_EXCLUSION];

export const fixableRejectionCodes = [50001, 50004, 50006, 50014, 50015, 500016, 50022];

export const restrictedBLimitsStates = [];
export const restrictedCLimitsStates = ['DC', 'LA', 'ME', 'NH', 'SD', 'VT'];
export const restrictedDLimitsStates = ['DC', 'LA', 'ME', 'MS', 'ND', 'NH', 'SD', 'VT'];
export const unlimitedDLimitsStates = ['CO'];
export const UNLIMITED_D_VALUE = -9999;

export const coverageFOffered = {
  AL: true,
  AR: true,
  AZ: false,
  CO: true,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KS: true,
  KY: true,
  LA: true,
  MA: true,
  MD: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  ME: true,
  NE: true,
  NH: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: true,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: true,
  WY: true
};

export const coverageDNotOffered = {
  DC: true,
  ME: true,
  MS: true,
  NH: true,
  SD: true,
  VT: true
};

export const coverageCANotOffered = {
  VA: true
};
export const coverageDRNotOffered = {
  ID: true,
  MA: true,
  ME: true,
  NH: true,
  TN: true,
  SD: true,
  VT: true,
  WY: true
};
export const coverageMINotOffered = {
  VA: true
};
export const coverageSTNotOffered = {
  ME: true,
  ND: true,
  NH: true,
  VA: true,
  VT: true
};
export const coverageSPNotOffered = {
  VA: true
};
export const coverageFairRentalNotOffered = {
  VA: true
};
export const coverageYardAndGardenNotOffered = {
  DC: true,
  SD: true,
  VA: true
};
export const coverageHomeownerProtectionNotOffered = {
  DC: true,
  ND: true,
  VA: true
};

export const offerExcessUM = {
  AL: false,
  AR: true,
  AZ: true,
  CO: false,
  DC: false,
  GA: false,
  IA: true,
  ID: false,
  IL: false,
  IN: true,
  KS: false,
  KY: false,
  LA: true,
  MA: false,
  MD: true,
  ME: false,
  MI: false,
  MO: false,
  MS: false,
  MT: true,
  ND: false,
  NE: true,
  NH: true,
  NM: false,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  SD: false,
  TN: false,
  TX: true,
  UT: false,
  VA: false,
  VT: true,
  WI: false,
  WV: true,
  WY: false
};

export const mineSubsidenceOptional = {
  IL: {
    all: true
  },
  IN: {
    CLAY: true,
    CRAWFORD: true,
    DAVIES: true,
    DAVIESS: true,
    DUBOIS: true,
    FOUNTAIN: true,
    GIBSON: true,
    GREENE: true,
    KNOX: true,
    LAWRENCE: true,
    MARTIN: true,
    MONROE: true,
    MONTGOMERY: true,
    ORANGE: true,
    OWEN: true,
    PARKE: true,
    PERRY: true,
    PIKE: true,
    POSEY: true,
    PUTNAM: true,
    SPENCER: true,
    SULLIVAN: true,
    VANDERBURG: true,
    VANDERBURGH: true,
    VERMILION: true,
    VERMILLION: true,
    VIGO: true,
    WARREN: true,
    WARRICK: true
  },
  KY: {
    BELL: true,
    BOYD: true,
    BREATHITT: true,
    BUTLER: true,
    CARTER: true,
    CHRISTIAN: true,
    CLAY: true,
    DAVIESS: true,
    EDMONSON: true,
    ELLIOT: true,
    FLOYD: true,
    GREENUP: true,
    HANCOCK: true,
    HARLAN: true,
    HENDERSON: true,
    HOPKINS: true,
    JACKSON: true,
    JOHNSON: true,
    KNOTT: true,
    KNOX: true,
    LAUREL: true,
    LAWRENCE: true,
    LEE: true,
    LETCHER: true,
    LESLIE: true,
    MARTIN: true,
    MCCREARY: true,
    MCLEAN: true,
    MORGAN: true,
    MUHLENBERG: true,
    OHIO: true,
    OWSLEY: true,
    PERRY: true,
    UNION: true,
    WEBSTER: true,
    WHITLEY: true,
    WOLFE: true
  },
  OH: {
    DELAWARE: true,
    ERIE: true,
    GEAUGA: true,
    LAKE: true,
    LICKING: true,
    MEDINA: true,
    OTTAWA: true,
    PORTAGE: true,
    PREBLE: true,
    SUMMIT: true,
    WAYNE: true
  },
  WV: {
    BERKELEY: true,
    CABELL: true,
    CALHOUN: true,
    HAMPSHIRE: true,
    HARDY: true,
    JACKSON: true,
    JEFFERSON: true,
    MONROE: true,
    MORGAN: true,
    PENDLETON: true,
    PLEASANTS: true,
    RITCHIE: true,
    ROANE: true,
    WIRT: true,
    WOOD: true
  }
};

export const mineSubsidenceRequired = {
  KY: {
    // stub placeholder to make this clear
    NONE: true
  },
  IN: {
    // stub placeholder to make this clear
    NONE: true
  },
  OH: {
    ATHENS: true,
    BELMONT: true,
    CARROLL: true,
    COLUMBIANA: true,
    COSHOCTON: true,
    GALLIA: true,
    GUERNSEY: true,
    HARRISON: true,
    HOCKING: true,
    HOLMES: true,
    JACKSON: true,
    JEFFERSON: true,
    LAWRENCE: true,
    MAHONING: true,
    MEIGS: true,
    MONROE: true,
    MORGAN: true,
    MUSKINGUM: true,
    NOBLE: true,
    PERRY: true,
    SCIOTO: true,
    STARK: true,
    TRUMBULL: true,
    TUSCARAWAS: true,
    VINTON: true,
    WASHINGTON: true
  },
  WV: {
    BARBOUR: true,
    BOONE: true,
    BRAXTON: true,
    BROOKE: true,
    CLAY: true,
    DODDRIDGE: true,
    FAYETTE: true,
    GILMER: true,
    GRANT: true,
    GREENBRIER: true,
    HANCOCK: true,
    HARRISON: true,
    KANAWHA: true,
    LEWIS: true,
    LINCOLN: true,
    LOGAN: true,
    MCDOWELL: true,
    MARION: true,
    MARSHALL: true,
    MASON: true,
    MERCER: true,
    MINERAL: true,
    MINGO: true,
    MONONGALIA: true,
    NICHOLAS: true,
    OHIO: true,
    POCAHONTAS: true,
    PRESTON: true,
    PUTNAM: true,
    RALEIGH: true,
    RANDOLPH: true,
    SUMMERS: true,
    TAYLOR: true,
    TUCKER: true,
    TYLER: true,
    UPSHUR: true,
    WAYNE: true,
    WEBSTER: true,
    WETZEL: true,
    WYOMING: true
  }
};

export const mandatoryWindHailDeductibleByZip = {};

export const mandatoryWindHailDeductibleByCounty = {
  LA: {
    AVOYELLES: 0.01,
    BIENVILLE: 0.01,
    BOSSIER: 0.01,
    CADDO: 0.01,
    CALDWELL: 0.01,
    CATAHOULA: 0.01,
    CLAIBORNE: 0.01,
    CONCORDIA: 0.01,
    'DE SOTO': 0.01,
    'EAST CARROLL': 0.01,
    FRANKLIN: 0.01,
    GRANT: 0.01,
    JACKSON: 0.01,
    LASallE: 0.01,
    LINCOLN: 0.01,
    LIVINGSTON: 0.01,
    MADISON: 0.01,
    MOREHOUSE: 0.01,
    NATCHITOCHES: 0.01,
    OUACHITA: 0.01,
    RAPIDES: 0.01,
    'RED RIVER': 0.01,
    RICHLAND: 0.01,
    SABINE: 0.01,
    TENSAS: 0.01,
    UNION: 0.01,
    VERNON: 0.01,
    WEBSTER: 0.01,
    'WEST CARROLL': 0.01,
    'WEST FELICIANA': 0.01,
    WINN: 0.01
  },
  MD: {
    ALLEGANY: 2500,
    GARRETT: 2500,
    WASHINGTON: 2500,
    FREDERICK: 2500,
    CARROLL: 2500,
    HOWARD: 2500,
    MONTGOMERY: 2500,
    CHARLES: 0.01,
    'PRINCE GEORGES': 0.01,
    "PRINCE GEORGE'S": 0.01,
    'ANNE ARUNDEL': 0.01,
    BALTIMORE: 0.01,
    'BALTIMORE COUNTY': 0.01,
    'BALTIMORE CITY': 0.01,
    HARFORD: 0.01,
    CECIL: 0.01,
    KENT: 0.01,
    'QUEEN ANNES': 0.01,
    "QUEEN ANNE'S": 0.01,
    CAROLINE: 0.01,
    CALVERT: 0.02,
    'ST MARYS': 0.02,
    'ST. MARYS': 0.02,
    'SAINT MARYS': 0.02,
    "ST. MARY'S": 0.02,
    "ST MARY'S": 0.02,
    "SAINT MARY'S": 0.02,
    TALBOT: 0.02,
    DORCHESTER: 0.02,
    WICOMICO: 0.02,
    SOMERSET: 0.02,
    WORCESTER: 0.02
  },
  MO: {
    JEFFERSON: 0.01,
    'St. Louis': 0.01,
    'ST. LOUIS CITY': 0.01,
    'ST. LOUIS COUNTY': 0.01
  },
  MS: {
    ADAMS: 0.02,
    COVINGTON: 0.02,
    FRANKLIN: 0.02,
    GEORGE: 0.02,
    HANCOCK: 0.02,
    HARRISON: 0.02,
    JACKSON: 0.02,
    'JEFFERSON DAVIS': 0.02,
    JONES: 0.02,
    LAWRENCE: 0.02,
    LINCOLN: 0.02,
    'PEARL RIVER': 0.02,
    STONE: 0.02,
    WAYNE: 0.02
  }
};

export const aopDeductibleIsAlwaysPercent = {
  TN: false,
  WY: false
};

export const windHailDeductibleCanVaryByCounty = {
  GA: {
    BRYAN: true,
    CAMDEN: true,
    CHATHAM: true,
    GLYNN: true,
    LIBERTY: true,
    MCINTOSH: true
  }
};

export const noWindHailDeductibleStates = {
  DC: true,
  ND: true,
  SD: true,
  NH: true,
  TN: true,
  VT: true,
  WY: true
};

export const rentersPolicyAvailable = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: false,
  FL: false,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KS: false,
  KY: false,
  LA: false,
  MA: false,
  MD: false,
  ME: false,
  MI: false,
  MO: true,
  MS: false,
  MT: false,
  ND: true,
  NE: true,
  NH: false,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: false,
  SD: false,
  TN: false,
  TX: false,
  UT: true,
  VA: true,
  VT: false,
  WI: true,
  WV: true,
  WY: false
};

export const standaloneRentersStates = {
  AZ: true,
  ND: true,
  NM: true,
  PA: true,
  CO: true
};

export const condoStates = {
  AZ: true,
  MI: true,
  MO: true,
  OH: true,
  PA: true,
  WI: true
};

export const localPremiumTaxStates = {
  KY: true
};

export const statePremiumTaxStates = {
  KY: true
};

export const noShowRoofSurfacesExtendedUnderXYearsStates = {
  GA: 15,
  AZ: 15
};

export const coverageRSForcedAtXYearsStates = {
  AL: 15,
  AR: 7,
  AZ: 15,
  CO: 15,
  GA: 15,
  IA: 15,
  ID: 15,
  IL: 15,
  IN: 15,
  KS: 15,
  LA: 15,
  MI: 15,
  MO: 15,
  MS: 15,
  MT: 15,
  NE: 15,
  OH: 15,
  OR: 15,
  PA: 15,
  SC: 15,
  SD: 15,
  TN: 15,
  UT: 15,
  VT: 15,
  WI: 15,
  WV: 15,
  WY: 15
};

export const minimumDrivingAge = {
  AL: 15,
  AK: 14,
  AZ: 15,
  AR: 14,
  CA: 15,
  CO: 15,
  CT: 16,
  DE: 16,
  DC: 16,
  FL: 15,
  GA: 15,
  HI: 14,
  ID: 14,
  IL: 15,
  IN: 15,
  IA: 14,
  KS: 14,
  KY: 16,
  LA: 15,
  ME: 15,
  MD: 15,
  MA: 16,
  MI: 14,
  MN: 15,
  MS: 15,
  MO: 15,
  MT: 14,
  NE: 15,
  NV: 15,
  NH: 15,
  NJ: 16,
  NM: 16,
  NY: 16,
  NC: 15,
  ND: 14,
  OH: 15,
  OK: 15,
  OR: 15,
  PA: 16,
  RI: 16,
  SC: 15,
  SD: 14,
  TN: 15,
  TX: 15,
  UT: 15,
  VT: 15,
  VA: 15,
  WA: 15,
  WV: 15,
  WI: 15,
  WY: 15
};

export const vehicleEquipmentStatusCode = {
  OPTIONAL: 'O',
  STANDARD: 'S',
  NO: 'N'
};

export const HomeSecurityPartnerCustomerType = {
  CURRENT: 'C',
  NEW_BRANCH: 'B'
};

export const newCustomersDefaultDevices = {
  ADT: {
    moistureDevices: 2,
    theftPreventionDevices: 2,
    motionDetectingDevices: 1,
    smokeDetectingDevices: 2,
    autoWaterShutoffDevices: false
  },
  SimpliSafe: {
    moistureDevices: 1,
    theftPreventionDevices: 1,
    motionDetectingDevices: 1,
    smokeDetectingDevices: 1,
    autoWaterShutoffDevices: false
  }
};

export const connectedHomePromoDetails = {
  SimpliSafe: {
    promoItems: [
      '7-piece security system guards against break-ins and fires--plus includes 24/7 video monitoring. A $403 value, yours FREE.',
      'Monitored by professionals 24/7 for only $17.99/month - with NO long-term contract. Your first month is FREE.',
      'Ranked the best overall home security system of 2021 by U.S. News and World Report'
    ],
    privacyPolicyUrl: 'https://simplisafe.com/privacy'
  },
  ADT: {
    promoItems: [
      'Get ADTs best offer PLUS receive 2 flood detectors FREE (Requires 36 month monitoring contract. Early termination and installation fees apply.)',
      'ADT is the # 1 smart home security provider in the U.S. (Source: Strategy Analytics, 2020)',
      '$0 Down*** 0% Interest 100% Real Protection. Now you can get the security you need with the smart home devices you want with $0 down and flexible, easy, low monthly payments on professionally installed equipment. (***Requires 0% APR installment agreement with equal monthly payments based on term/equipment selected. Well-qualified credit. Requires monthly monitoring contract for finance term selected. Early term fees apply.)'
    ],
    privacyPolicyUrl: 'https://www.adt.com/about-adt/legal/privacy-policy'
  },
  Kangaroo: {
    privacyPolicyUrl: 'https://info.heykangaroo.com/legal#legal-privacy-policy'
  }
};

export const HighestEducation = {
  H: 'High School or less',
  S: 'Some College',
  A: 'Associates Degree',
  B: 'Bachelors Degree',
  M: 'Masters Degree',
  D: 'Doctoral Degree'
};

export const violationCodeToDescription = {
  AAF: 'At Fault Accident',
  AFM: 'Accident found on MVR only at renewal - Not Chargeable',
  ANC: 'Waived Claim – Closed',
  ANO: 'Waived Claim – Open',
  ASW: 'Accident Surcharge Waived',
  BOT: 'Comprehensive Claim',
  CCW: 'Comprehensive Claim - Weather',
  CML: 'Commercial Vehicle Violation',
  CMP: 'Comprehensive Claim',
  CMU: 'Comprehensive Claim Less Than $1000',
  CRD: 'Careless or Improper Operation',
  DEQ: 'Defective Equipment',
  DEV: 'Traffic Device/Sign',
  DR: 'Drag Racing',
  DWI: 'Drive Under Influence',
  FAR: 'False Reporting or Perjury',
  FDL: 'Foreign Drivers Lic',
  FEL: 'Auto Theft/Felony Motor Vehicle',
  FFR: 'Failure to File Required Report',
  FLE: 'Fleeing from Police',
  FRA: 'Failure to Report an Accident',
  FTC: 'Following Too Close',
  FTY: 'Failure to Yield',
  HOM: 'Vehicular Homicide',
  IP: 'Improper Passing',
  IT: 'Improper Turn',
  LDL: "Operating Without Owner's Consent",
  LIC: 'License/Credentials Violation',
  LTS: 'Leaving the Scene',
  MAJ: 'Other Serious Violation',
  MMV: 'Minor Moving Violation',
  NAF: 'Not At Fault Accident',
  NFX: 'Waived Not At Fault Accident',
  PUA: 'Permissive Use At Fault Accident',
  PUN: 'Permissive Use Not At Fault Accident',
  REF: 'Passing of a School Bus',
  RKD: 'Reckless Driving',
  SAF: 'Safety Violation',
  SLV: 'Serious License Violations',
  SPD: 'Speeding',
  SUS: 'Driving Under Suspension',
  TMP: 'Dispute - At Fault Accident',
  UDR: 'Unverifiable Record',
  WSR: 'Wrong Way on a One Way Street'
};

export const homeClaimCodeToDescription = {
  D: 'Fire',
  E: 'Theft, Liability, Vandalism',
  F: 'All Other Claims (not Fire, Water, Theft, Liability or Vandalism)',
  G: 'Water'
};

export const fpcClassesImpactingPrice = ['7', '8', '8B', '9'];
export const financialResponsibilityTiersImpactingPrice = ['L-1', 'M-1', 'N-1', 'O-1', 'P-1', 'Q-1', 'R-1'];
export const policyType = {
  Home: 'H',
  Auto: 'A',
  ARBundle: 'AR',
  HABundle: 'HA',
  Renters: 'R',
  Condo: 'C',
  CABundle: 'CA',
  HomeBundle: 'HB',
  AutoBundle: 'AB'
};

export const policyTypelabels = { H: 'home', A: 'auto', R: 'rent', C: 'cond' };
export const paymentMethod = { CreditCard: 'C', ACH: 'E', Escrow: 'W' };
export const paymentType = { Monthly: 'I', OneTime: 'F', Escrow: 'E' };

export const stripeBankStatus = {
  New: 'new',
  Validated: 'validated',
  Verified: 'verified',
  VerificationFailed: 'verification_failed',
  Errored: 'errored'
};

export const onboardingTasks = {
  StressFreeSwitchHome: 'StressFreeSwitchHome',
  StressFreeSwitchAuto: 'StressFreeSwitchAuto',
  StressFreeSwitchRenters: 'StressFreeSwitchRenters',
  StressFreeSwitchUmbrella: 'StressFreeSwitchUmbrella',
  CompleteInventory: 'Complete Inventory',
  ProvideProofOfGoodStudent: 'Provide Proof of Good Student',
  ProvideProofOfConnectedHome: 'Provide Proof of Connected Home',
  SignApplication: 'Sign Application',
  ConnectedHomeRenewal: 'Need Proof of Connected Home at Renewal'
};

export const monitoredStatusThatNeedProof = ['P', 'VP', 'C'];

export const taskDocumentType = {
  Policy: 'Policy',
  Internal: 'Internal'
};

export const branchInitiatedCancelReasons = ['CNCN', 'CNPP', 'CNPY', 'CNUW', 'CNUV', 'CNUH', 'CNSR', 'CNFP'];
export const flatCancelReasons = ['CNDP', 'CNMS', 'CNRW'];

export const acceptableCancelCodesForRewrite = ['CNRW'];

// If stormShutters checkbox is wanted to be shown in Advanced Windstorm section, add the state to 'stormShuttersInAdvancedWindstormSectionStates' array too.
export const stormShuttersStates = ['AL', 'MD', 'MS', 'TX', 'SC', 'VA'];
export const stormShuttersInAdvancedWindstormSectionStates = ['SC', 'LA'];
export const stormShuttersVerificationFormLinkStates = {
  SC: 'https://app.getguru.com/card/Txd87yMc/South-Carolina-Mitigation-Verification-Affidavit'
};
export const editableHouseholdMembersStates = ['FL', 'IA', 'KS', 'NM', 'OR', 'SD'];
export const uimAddedOnStates = ['GA'];
export const earthquakeStates = ['KY', 'CA'];
export const palomarErrorPreventPurchasingStates = ['CA'];
export const floodStates = ['TN', 'CA'];
export const nonQuinstreetStates = ['MA', 'AK'];
export const isResidentialOwnerStates = ['FL'];
export const editableResidenceInsuranceType = ['MA'];
export const residenceInsuranceTypes = {
  Home: 'O',
  Manufactured_Mobile: 'M',
  Condo: 'C',
  Renters: 'R',
  None_NotApplicable: 'T'
};

export const annualMilesDrivenLimits = {
  IA: {
    max: 30000,
    step: 1000
  },
  MA: {
    max: 30000,
    step: 1000
  },
  NE: {
    max: 30000,
    step: 1000
  },
  OR: {
    max: 30000,
    step: 1000
  },
  SD: {
    max: 30000,
    step: 1000
  }
};

export const hideHighestEducationStates = ['CA', 'FL', 'GA', 'ID', 'KS', 'MA', 'NY', 'VT'];
export const hideGenderStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
export const mustRenewAutoLimit = {
  WV: {
    A: 4,
    H: 4
  }
};
export const equipmentBreakdownServiceLineCoveragesStates = [
  'AR',
  'AZ',
  'DC',
  'GA',
  'IA',
  'IL',
  'IN',
  'KY',
  'LA',
  'MD',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NE',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'TX',
  'UT',
  'WI',
  'WV'
];

// dates EBSL should start in each state
export const EBSLstateDate = {
  AR: '01-20-2022',
  AZ: '01-20-2022',
  DC: '08-29-2022',
  GA: '06-01-2023',
  IA: '01-20-2022',
  IL: '08-30-2022',
  IN: '07-01-2022',
  KY: '08-20-2022',
  LA: '08-20-2022',
  MD: '07-21-2022',
  MI: '07-01-2022',
  MO: '08-13-2022',
  MS: '08-29-2022',
  MT: '01-20-2022',
  ND: '08-29-2022',
  NE: '07-15-2022',
  NM: '08-29-2022',
  OH: '01-20-2022',
  OK: '08-29-2022',
  OR: '08-01-2022',
  PA: '07-01-2022',
  TX: '07-26-2022',
  UT: '08-01-2022',
  WI: '07-01-2022',
  WV: '08-01-2022'
};

export const ibscDescriptionByState = {
  LA: 'We recommend 20% Incremental Coverage. This way, if it costs more to rebuild your home, you’ll have added peace of mind.',
  MS: 'We recommend 20% Incremental Coverage. This way, if it costs more to rebuild your home, you’ll have added peace of mind.',
  SD: 'We recommend 20% Incremental Coverage. This way, if it costs more to rebuild your home, you’ll have added peace of mind.'
};

export const driversLicenseRegexByState = {
  AB: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  AK: {
    regex: /^[0-9]{1,7}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 7 numbers'
  },
  AL: {
    regex: /^[0-9]{7,10}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 7 to 10 numbers'
  },
  AR: {
    regex: /^\d{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  AZ: {
    regex: /^(([a-z][0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter + 8 numbers or Social Security number'
  },
  BC: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  CA: {
    regex: /^[a-z]\d{7}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 7 numbers'
  },
  CO: {
    regex: /^\d{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter + up to 6 numbers'
  },
  CT: {
    regex: /^\d{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  DC: {
    regex: /^(([a-z]{2}\d{8})|(\d{7})|(\d{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 2 letters + 8 numbers, or 7 numbers, or Social Security number'
  },
  DE: {
    regex: /^[0-9]{1,7}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 7 numbers'
  },
  FL: {
    regex: /^[a-z]\d{11,12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 11-12 numbers'
  },
  GA: {
    regex: /^[0-9]{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  HI: {
    regex: /^((\d{9})|([H][0-9]{8}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of letter H + 8 numbers or Social Security number'
  },
  IA: {
    regex: /^((\d{9})|(\d{3}[a-z]{2}\d{4}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 9 numbers/letters (4th and 5th characters are letters)'
  },
  ID: {
    regex: /^(([a-z]{2}[0-9]{6}[a-z])|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 2 letters + 6 numbers + 1 letter or 9 numbers (issued prior to 5/1/93)'
  },
  IL: {
    regex: /^[a-z][0-9]{11}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 11 numbers'
  },
  IN: {
    regex: /^(([a-z]\d{9})|(\d{10}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 10 numbers or 1 letter + 9 numbers'
  },
  KS: {
    regex: /^((\d{9})|([a-z]\d{8})|([a-z0-9]{6}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 6 numbers/letters or 9 numbers or Social Security number or 1 leter + 9 numbers'
  },
  KY: {
    regex: /^(([a-z][0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 8 numbers or Social Security number'
  },
  LA: {
    regex: /^[0-9]{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers. If less than 9 numbers, fill in with zeros on the front end'
  },
  MA: {
    regex: /^(([a-z]{2}[0-9]{7})|([a-z][0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter/number + 8 numbers'
  },
  MB: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  MD: {
    regex: /^(([a-z][0-9]{12})|(md\d{11}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 12 numbers, or "MD" followed by 11 digits'
  },
  ME: {
    regex: /^(\d{7})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 7 numbers'
  },
  MI: {
    regex: /^[a-z]\d{12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 12 numbers'
  },
  MN: {
    regex: /^[a-z]\d{12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 12 numbers'
  },
  MO: {
    // eslint-disable-next-line prefer-regex-literals
    regex: new RegExp(
      '^(([a-z][a-z0-9]{5,9})|([0-9]{9,10})|([0-9]{9}[a-z])|([a-z][0-9]{15,16})|([a-z][0-9]{15}[a-z])|([0-9]{3}[a-z][0-9]{6}))$',
      'i'
    ),
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage:
      'Must consist of 9 to 10 numbers or 9 numbers + 1 letter or 1 letter + 15 to 16 numbers or 1 letter + 15 numbers + 1 letter or 1 letter + 5 to 9 letters/numbers or 3 numbers + 1 letter + 6 numbers'
  },
  MS: {
    regex: /^[0-9]{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  MT: {
    regex: /^(([a-z0-9]{9})|([a-z0-9]{13}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 13 characters or 9 numbers or 9 letters/numbers'
  },
  NB: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NC: {
    regex: /^[0-9]{1,12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 12 numbers'
  },
  ND: {
    regex: /^(([a-z]{3}[0-9]{6})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 3 letters + 6 numbers or Social Security number'
  },
  NE: {
    regex: /^[a-z][0-9]{3,8}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 3-8 numbers'
  },
  NF: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NH: {
    regex: /^[a-z0-9]{10,11}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 10-11 letters/numbers'
  },
  NJ: {
    regex: /^[a-z][0-9]{14}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 14 numbers '
  },
  NM: {
    regex: /^[0-9]{8,9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 to 9 numbers '
  },
  NS: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NT: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NV: {
    regex: /^(([0-9]{9,10})|([0-9]{12})|(X[0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 to 10 numbers or 12 numbers or X + 8 numbers or Social Security number '
  },
  NY: {
    regex: /^(([0-9]{9})|([a-z][0-9]{16,20}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter + 16 to 20 numbers or 16 - 20 numbers'
  },
  OH: {
    regex: /^([a-z]{2}[0-9]{6})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 2 letters + 6 numbers'
  },
  OK: {
    regex: /^([a-z]{1}[0-9]{9})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 9 numbers'
  },
  ON: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  OR: {
    regex: /^(([0-9]{1,7})|([a-z][0-9]{6})|([a-z]{2}[0-9]{5}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 7 numbers or 1 letter + 6 numbers or 2 letters + 5 numbers'
  },
  PA: {
    regex: /^[0-9]{8}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 numbers'
  },
  PE: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  PQ: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  RI: {
    regex: /^(([V][0-9]{6})|([0-9]{7}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 7 numbers or V + 6 numbers'
  },
  SC: {
    regex: /^[0-9]{1,10}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 10 numbers'
  },
  SD: {
    regex: /^(([0-9]{6})|([0-9]{8,9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 6 or 8 to 9 numbers'
  },
  SK: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  TN: {
    regex: /^[0-9]{8,9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 to 9 numbers'
  },
  TX: {
    regex: /^([0-9]{8}[a-zA-Z]|[0-9]{8})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 numbers'
  },
  UT: {
    regex: /^(([0-9]{4,9})|(0[0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 4 to 9 numbers or 10 numbers (first position must be 0)'
  },
  VA: {
    regex: /^(([a-z][0-9]{8})|([0-9]{9})|([0-9]{12}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 12 numbers or 1 letter + 8 numbers or Social Security number'
  },
  VT: {
    regex: /^[0-9]{7}[A0-9]$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 numbers or 7 numbers + A'
  },
  WA: {
    regex: /^[a-z0-9*]{12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 12 letters/numbers'
  },
  WI: {
    regex: /^[a-z][0-9]{13}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 13 numbers'
  },
  WV: {
    regex: /^(([ABCDEFISX][0-9]{6})|([X1][X][0-9]{5})|([a-z][0-9]{6})|([0-9]{7}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage:
      'Must consist of 1 letter + 6 numbers or 2 letters + 5 numbers or 1 letter + 1 number + 5 numbers or 7 numbers'
  },
  WY: {
    regex: /^(([0-9]{9})|([0-9]{6}[-][0-9]{3}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: "Must consist of 9 numbers or 6 numbers then a '-' then 3 more numbers"
  },
  YT: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  }
};

export const allowedDirectMonolineCurrentCarriers = [
  '00109', // Everspan
  '00128',
  '00182',
  '00270',
  '00273',
  '00277',
  '00349',
  '00366',
  '00391',
  '00460',
  '00466',
  '00542',
  '00548',
  '00586',
  '00588',
  '00601',
  '00603',
  '00609',
  '00638',
  '00643',
  '00649',
  '00662',
  '00671',
  '00712',
  '00762',
  '00764',
  '00934',
  '00956',
  '01737',
  '01743',
  '01744',
  '01762',
  '01767',
  '01812',
  '01839',
  '01852',
  '01853',
  '01864',
  '01873',
  '01891',
  '01896',
  '01900',
  '01921',
  '01978',
  '01987',
  '01996',
  '02001',
  '02002',
  '02016',
  '02017',
  '02018',
  '02022',
  '02023',
  '02028',
  '02084',
  '02085',
  '02086',
  '02088',
  '02147',
  '02159',
  '02171',
  '02172',
  '02173',
  '02174',
  '02204',
  '02205',
  '02229',
  '02230',
  '02231',
  '02232',
  '02234',
  '02235',
  '02253',
  '02254',
  '02276',
  '02282',
  '02283',
  '02286',
  '02287',
  '02289',
  '02290',
  '02356',
  '02357',
  '02358',
  '02360',
  '02361',
  '02363',
  '02378',
  '02379',
  '02385',
  '02386',
  '02389',
  '02393',
  '02394',
  '02407',
  '02408',
  '02446',
  '02447',
  '02448',
  '02452',
  '02453',
  '02476',
  '02477',
  '02478',
  '02479',
  '02483',
  '02496',
  '02513',
  '02516',
  '02517',
  '02518',
  '02520',
  '02523',
  '02526',
  '02538',
  '02550',
  '02577',
  '02594',
  '02609',
  '02610',
  '02611',
  '02612',
  '02613',
  '02614',
  '02634',
  '02640',
  '02650',
  '02665', // GSNIC
  '02701',
  '02709',
  '02761',
  '02784',
  '02855',
  '02866',
  '03028',
  '03045',
  '03100',
  '03132',
  '03288',
  '03289',
  '03292',
  '03299',
  '03312',
  '03539',
  '03566',
  '03596',
  '03645',
  '03652',
  '03690',
  '03733',
  '03756',
  '03779',
  '03791',
  '03792',
  '03794',
  '03795',
  '03831',
  '04003',
  '04025',
  '04046',
  '04049',
  '04083',
  '04097',
  '04158',
  '04159',
  '04240',
  '04274',
  '04287',
  '04305',
  '04306',
  '04311',
  '04456',
  '04461',
  '04465',
  '04675',
  '04785',
  '04865',
  '04869',
  '04938',
  '04976',
  '06627',
  '10106',
  '10130',
  '10346',
  '10419',
  '10648',
  '10678',
  '10709',
  '10724',
  '10745',
  '10764',
  '10765',
  '10777',
  '10852',
  '11020',
  '11024',
  '11025',
  '11026',
  '11027',
  '11055',
  '11087',
  '11088',
  '11150',
  '11224',
  '11241',
  '11244',
  '11246',
  '11247',
  '11248',
  '11300',
  '11302',
  '11325',
  '11354',
  '11417',
  '11421',
  '11441',
  '11578',
  '11654',
  '11665',
  '11688',
  '11689',
  '11698',
  '11699',
  '11702',
  '11703',
  '11758',
  '11759',
  '11760',
  '11761',
  '11762',
  '11778',
  '11779',
  '11780',
  '11789',
  '11790',
  '11794',
  '11802',
  '11872',
  '11946',
  '11979',
  '12051',
  '12053',
  '12058',
  '12059',
  '12078',
  '12089',
  '12106',
  '12120',
  '12121',
  '12128',
  '12150',
  '12163',
  '12212',
  '12213',
  '12238',
  '12410',
  '12421',
  '12425',
  '12460',
  '12535',
  '12536',
  '12560',
  '12561',
  '12563',
  '12569',
  '12612',
  '12710',
  '12711',
  '13016',
  '13080',
  '13082',
  '13083',
  '13107',
  '13866',
  '13878',
  '13917',
  '14235',
  '14301',
  '14302',
  '14303',
  '18538',
  '22001',
  '22059',
  '22601',
  '23178',
  '66716', // BIX
  '86485'
];

export const nodePathToFriendlyName = {
  'homeCoverage.coverageA': 'Replacement Cost',
  'homeCoverage.coverageIBSC': 'Incremental Building Structures Coverage Limit',
  'homeCoverage.coverageBCPctOfA': 'Building Codes Coverage Limit',
  'homeCoverage.coverageBPctOfA': 'Other Structures Coverage Limit',
  'homeCoverage.coverageCPctOfA': 'Personal Property Coverage Limit',
  'homeCoverage.coverageDPctOfA': 'Additional Living Expenses Coverage Limit',
  'homeCoverage.coverageX': 'Familiy Liability Coverage Limit',
  'homeCoverage.coverageY': 'Guest Medical Coverage Limit',
  'homeCoverage.coverageBP': 'Additional Coverage on Business Property Limit',
  'homeCoverage.coverageCA': 'Additional Coverage on Camera Property Limit',
  'homeCoverage.coverageDR': 'Electronic Data Recovery Coverage',
  'homeCoverage.coverageF': 'Limit on Fire Department Charges',
  'homeCoverage.coverageG': 'Loss Assessments Limit',
  'homeCoverage.coverageJ': 'Additional Coverage on Jewelry, Watches, and Furs Limit',
  'homeCoverage.coverageMI': 'Additional Coverage on Musical Instruments Limit',
  'homeCoverage.coverageMS': 'Mine Subsidence Coverage',
  'homeCoverage.coverageMSALE': 'Mine Subsidence ALE Coverage',
  'homeCoverage.coverageSP': 'Additional Coverage on Sports Equipment Limit',
  'homeCoverage.coverageST': 'Additional Coverage on Silverware Theft Limit'
};

export const seniorAge = {
  MA: 65
};

export const inexperiencedYearsLimit = {
  MA: 7
};

export const deletedDriversReasons = {
  PRIOR_OWNER: 'PRIOR_OWNER',
  NON_RELATIVE: 'NON_RELATIVE',
  NON_LICENSED: 'NON_LICENSED',
  MILITARY_AWAY: 'MILITARY_AWAY',
  RESIDENT_RELATIVE_OWN_INS: 'RESIDENT_RELATIVE_OWN_INS',
  DECEASED: 'DECEASED'
};

export const nonRenewalNotificationByState = {
  AL: {
    A: 20,
    H: 30
  },
  AK: {
    A: 20,
    H: 20
  },
  AZ: {
    A: 45,
    H: 30
  },
  AR: {
    A: 30,
    H: 30
  },
  CA: {
    A: 75,
    H: 75
  },
  CO: {
    A: 30,
    H: 60
  },
  CT: {
    A: 60,
    H: 60
  },
  DE: {
    A: 20,
    H: 30
  },
  DC: {
    A: 30,
    H: 30
  },
  FL: {
    A: 45,
    H: 120
  },
  GA: {
    A: 30,
    H: 30
  },
  HI: {
    A: 30,
    H: 30
  },
  ID: {
    A: 30,
    H: 30
  },
  IL: {
    A: 30,
    H: 30
  },
  IN: {
    A: 20,
    H: 20
  },
  IA: {
    A: 30,
    H: 30
  },
  KS: {
    A: 30,
    H: 30
  },
  KY: {
    A: 75,
    H: 75
  },
  LA: {
    A: 20,
    H: 30
  },
  ME: {
    A: 30,
    H: 30
  },
  MD: {
    A: 45,
    H: 45
  },
  MA: {
    A: 45,
    H: 45
  },
  MI: {
    A: 30,
    H: 30
  },
  MN: {
    A: 59,
    H: 59
  },
  MS: {
    A: 30,
    H: 30
  },
  MO: {
    A: 20,
    H: 30
  },
  MT: {
    A: 45,
    H: 45
  },
  NE: {
    A: 20,
    H: 59
  },
  NV: {
    A: 30,
    H: 30
  },
  NH: {
    A: 45,
    H: 45
  },
  NJ: {
    A: 59,
    H: 30
  },
  NM: {
    A: 30,
    H: 30
  },
  NY: {
    A: 45,
    H: 45
  },
  NC: {
    A: 59,
    H: 30
  },
  ND: {
    A: 30,
    H: 45
  },
  OH: {
    A: 30,
    H: 30
  },
  OK: {
    A: 20,
    H: 30
  },
  OR: {
    A: 30,
    H: 30
  },
  PA: {
    A: 60,
    H: 30
  },
  RI: {
    A: 30,
    H: 30
  },
  SC: {
    A: 15,
    H: 59
  },
  SD: {
    A: 59,
    H: 30
  },
  TN: {
    A: 30,
    H: 30
  },
  TX: {
    A: 60,
    H: 60
  },
  UT: {
    A: 30,
    H: 30
  },
  VT: {
    A: 45,
    H: 45
  },
  VA: {
    A: 45,
    H: 30
  },
  WA: {
    A: 20,
    H: 45
  },
  WV: {
    A: 45,
    H: 30
  },
  WI: {
    A: 59,
    H: 59
  },
  WY: {
    A: 30,
    H: 45
  }
};

export const everspanAndSCORStatesMapToPolicyType = {
  VT: ['A', 'H'],
  NH: ['A', 'H'],
  MA: ['A', 'H'],
  KS: ['A', 'H'],
  WY: ['A', 'H'],
  AL: ['A', 'H'],
  ID: ['H'],
  VA: ['A', 'H'],
  SC: ['A', 'H'],
  SD: ['A', 'H'],
  TN: ['A', 'H'],
  CO: ['A', 'H']
};

export const cancelCodeInformationalArray = [
  {
    cancelCode: 'CNDC',
    cancelReasonPlainText: 'Member Dissatisfied with Claims',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNPR',
    cancelReasonPlainText: 'Member Dissatisfied with Price',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNDR',
    cancelReasonPlainText: 'Member Dissatisfied with Roadside',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Roadside'
  },
  {
    cancelCode: 'CNDB',
    cancelReasonPlainText: 'Member Dissatisfied with Service',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNDA',
    cancelReasonPlainText: 'Member Dissatisfied with Agency',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNMS',
    cancelReasonPlainText: 'Member Misrepresentation',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Misrepresentation'
  },
  {
    cancelCode: 'CNPY',
    cancelReasonPlainText: 'Member Non-Payment',
    // ODEN (triggered by insurer)
    cancelNoticeName: 'CNPY',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date]',
    boxTwoText: 'Reason(s) for Cancellation: Non-Payment'
  },
  {
    cancelCode: 'CNPY_X',
    cancelReasonPlainText: 'Non-Payment of Premium (Exception)',
    // CD (triggered by customer request)
    cancelNoticeName: 'CNPY',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date]',
    boxTwoText: 'Reason(s) for Cancellation: Non-Payment'
  },
  {
    cancelCode: 'CNID',
    cancelReasonPlainText: 'Documents Not Signed',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Documents Not Signed'
  },
  {
    cancelCode: 'CNCN',
    cancelReasonPlainText: 'Ineligible Condition of Home',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Ineligible Condition of Home',
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNMV',
    cancelReasonPlainText: 'Moving, Branch Eligible',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNMI',
    cancelReasonPlainText: 'Moving, Branch Ineligible',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNNO',
    cancelReasonPlainText: 'Other Customer Cancellation',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNUW',
    cancelReasonPlainText: 'Other Underwriting',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting; No Insurable Interest'
  },
  {
    cancelCode: 'CNSO',
    cancelReasonPlainText: "Sold the Insured Property/Didn't Purchase",
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNDP',
    cancelReasonPlainText: 'Duplicate Policy',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Duplicate Policy'
  },
  {
    cancelCode: 'CNIP',
    cancelReasonPlainText: 'Invalid Policy',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Invalid Policy'
  },
  {
    cancelCode: 'CNPP',
    cancelReasonPlainText: 'No Proof of Prior',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: No Proof of Prior Insurance'
  },
  {
    cancelCode: 'CNAP',
    cancelReasonPlainText: 'Accidental Purchase',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNBX',
    cancelReasonPlainText: 'Member Convert to Bix',
    // no cancellation docs generated for CNBX
    cancelNoticeName: '',
    boxOneText: '',
    boxTwoText: ''
  },
  {
    cancelCode: 'CNFP',
    cancelReasonPlainText: 'Failure to provide underwriting information',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date]',
    boxTwoText: 'Reason(s) for Cancellation: Failure to provide underwriting information'
  },
  {
    cancelCode: 'CNNR',
    cancelReasonPlainText: 'Non-renewal',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-renewal: [fill in]',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NRAT',
    cancelReasonPlainText: 'Agency Termination',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Agent/agency no longer represents the company',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NREV',
    cancelReasonPlainText: 'Everspan Non-renewal',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText:
      'Reason(s) for Non-Renewal: Everspan Insurance Company is no longer offering insurance policies through Branch in your state due to a loss of reinsurance',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NRSC',
    cancelReasonPlainText: 'GSNIC Non-renewal',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText:
      'Reason(s) for Non-Renewal: The insurer, General Security National Insurance Company, has decided to stop offering personal lines insurance policies in your state.',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNDE',
    cancelReasonPlainText: 'Member Deceased',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNAG',
    cancelReasonPlainText: 'Agency Book Roll',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Previous Agency Policy'
  },
  {
    cancelCode: 'CNBN',
    cancelReasonPlainText: 'BIX Forced Non-Renewal',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText:
      'Reason(s) for Non-Renewal: General Security National Insurance Company is no longer offering policies in your state. ',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNDY',
    cancelReasonPlainText: 'Bix Opt out',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Opt Out of BIX Policy '
  },
  {
    cancelCode: 'NRUW',
    cancelReasonPlainText: 'Non-Renewal For Underwriting Reasons',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: [Fill In]',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNUV',
    cancelReasonPlainText: 'Underwriting: Ineligible Vehicle',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting; Ineligible Vehicle'
  },
  {
    cancelCode: 'CNUH',
    cancelReasonPlainText: 'Underwriting: Ineligible Driving History',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting; Ineligible Driving History'
  },
  {
    cancelCode: 'CNRW',
    cancelReasonPlainText: 'Policy Rewrite',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNSR',
    cancelReasonPlainText: 'Underwriting: Substantial Change in Risk',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting: Substantial Change in Risk'
  },
  {
    cancelCode: 'NCAT',
    cancelReasonPlainText: 'Reinsurance Capacity',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Catastrophe Risk Exposure Management',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NACP',
    cancelReasonPlainText: 'Policy Not Accepted',
    // No cancellation docs will be generated for NACP
    cancelNoticeName: '',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Policy Not Accepted'
  },
  {
    cancelCode: 'NRLV',
    cancelReasonPlainText: 'Underwriting Reasons - Loss and/or violation activity',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Underwriting Reasons - Loss and/or violation activity',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NRLS',
    cancelReasonPlainText: 'Underwriting Reasons - Invalid license status',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Underwriting Reasons - Invalid license status',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NRFP',
    cancelReasonPlainText: 'Underwriting Reasons - Failure to provide underwriting information',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Underwriting Reasons - Failure to provide underwriting information',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NRCH',
    cancelReasonPlainText: 'Underwriting Reasons - Property condition and/or hazard concerns',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Underwriting Reasons - Property condition and/or hazard concerns',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'NRPC',
    cancelReasonPlainText: 'Underwriting Reasons - Property claims activity',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: Underwriting Reasons - Property claims activity',
    nonRenew: true,
    allowFreeFormNote: true
  }
];

export const AssurityPaymentTypes = {
  BankAccount: 'BankAccount',
  CreditCard: 'CreditCard'
};
