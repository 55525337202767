/* eslint-disable no-console */
import gql from 'graphql-tag';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Auth as AwsAuth, Hub } from 'aws-amplify';
import { AuthProvider as DescopeProvider } from '@descope/react-sdk';

import awsConfig from 'aws-exports';
import { withToast } from 'core/components/toast';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { initializeFrontChatAndIdentify, identifyUserInUpscope } from 'configure-services';
import { AuthContext } from './auth.context';
import { identify, track } from 'core/helpers/analytics';
import { signOut } from './oauth-helpers';
import oAuthStorage from './oauth-storage';

AwsAuth.configure({
  authenticationFlowType: 'CUSTOM_AUTH'
});

function getUserFromPayload(payload) {
  return {
    username: payload['cognito:username'],
    groups: payload['cognito:groups'],
    email: payload.email,
    firstName: payload.given_name,
    lastName: payload.family_name,
    phoneNumber: payload.phone_number,
    affinityCode: payload['custom:affinity_code'],
    licenseNumber: payload['custom:license_number'],
    agencyRole: payload['custom:agency_role']
  };
}

function parseCustomAttribute(attribute) {
  try {
    return JSON.parse(attribute);
  } catch (e) {
    return [];
  }
}

function permissions(payload) {
  if (!payload['cognito:groups'] || !payload['cognito:groups'].length) {
    return {
      isService: false,
      isSales: false,
      canEdit: false
    };
  }

  // licensed agent groups
  const isService = payload['cognito:groups']?.includes('Service');
  const isSales =
    payload['cognito:groups']?.includes('InternalSales') || payload['cognito:groups']?.includes('ExternalSales');
  const isInternalSales = payload['cognito:groups']?.includes('InternalSales');
  const isExternalSales = payload['cognito:groups']?.includes('ExternalSales');
  const isTeamLeader = payload['cognito:groups']?.includes('TeamLeaders');

  // logic surrounding licensed and unlicensed states for external users
  const isAgency =
    !!parseCustomAttribute(payload['custom:affinity_groups']).length ||
    !!parseCustomAttribute(payload['custom:affinity_group_regex']).length;
  const parsedAllowedStates = parseCustomAttribute(payload['custom:allowed_states']);
  const parsedUnlicensedStates = parseCustomAttribute(payload['custom:unlicensed_states']);

  // individual features access
  const canReinstate = payload['cognito:groups']?.includes('Reinstatement');
  const canRescindCancellation = payload['cognito:groups']?.includes('RescindCancellation');
  const canSeeFullOfferDetails = payload['cognito:groups']?.includes('CompleteOfferDetails');
  const canScrubIncidents = payload['cognito:groups']?.includes('ScrubIncidents');
  const canModifyAffinityAndLeadSource = payload['cognito:groups']?.includes('ModifyAffinityCodes');
  const canBackDate = payload['cognito:groups']?.includes('CanBackDate');
  const canModifyBillingId = payload['cognito:groups']?.includes('ModifyBillingID');
  const canViewClarionDoorData = payload['cognito:groups']?.includes('ViewClarionDoorData');
  const canAddCarsManually = payload['cognito:groups']?.includes('AddCarsManually');
  const canAutoRenew = payload['cognito:groups']?.includes('CanAutoRenew');
  const canAddHoldCards = payload['cognito:groups']?.includes('CanAddHoldCards');
  const canChangeExclusions = payload['cognito:groups']?.includes('CanChangeExclusions');
  const hasUnlicensedGroup = payload['cognito:groups']?.includes('Unlicensed');
  const canToggleEmployeeDiscount = payload['cognito:groups']?.includes('CanToggleEmployeeDiscount');
  const canClearUDRs = payload['cognito:groups']?.includes('CanClearUDRs');
  const canEditHomeAddress = payload['cognito:groups']?.includes('CanEditHomeAddress');
  const canEditEffectiveDate = payload['cognito:groups']?.includes('CanEditEffectiveDate');
  const canFlagAsSuspicious = payload['cognito:groups']?.includes('CanFlagSuspiciousAccounts');
  const canManuallyChargeFee = payload['cognito:groups']?.includes('CanManuallyChargeFee');
  const canQuoteQuinstreet = payload['cognito:groups']?.includes('CanQuoteQuinstreet');
  const canClearUWFormRejections = payload['cognito:groups']?.includes('CanClearUWFormRejections');
  const canManuallyChargePolicy = payload['cognito:groups']?.includes('CanManuallyChargePolicy');
  const canRewritePolicy = payload['cognito:groups']?.includes('CanRewritePolicy');
  const canRemoveExcludedDriver = payload['cognito:groups']?.includes('CanRemoveExcludedDriver');
  const canCancelReasonCNIP = payload['cognito:groups']?.includes('CanCancelReasonCNIP');
  const canCancelReasonCNMS = payload['cognito:groups']?.includes('CanCancelReasonCNMS');
  const canCancelReasonCNPY_X = payload['cognito:groups']?.includes('CanCancelReasonCNPYX');
  const canRejectPIP = payload['cognito:groups']?.includes('CanRejectPIP');
  const showAgencyPortal = payload['cognito:groups']?.includes('ShowAgencyPortal');
  const hasCanQuoteGroup = payload['cognito:groups']?.includes('CanQuote');
  const hasCanViewPolicyGroup = payload['cognito:groups']?.includes('CanViewPolicy');
  const canViewQlikReports = payload['cognito:groups']?.includes('CanViewQlikReports');
  const canDownloadAnonDocs = payload['cognito:groups']?.includes('CanDownloadAnonDocs');
  const canReinstateCNPY = payload['cognito:groups']?.includes('CanReinstateCNPY');
  const canToggleCreditCheck = payload['cognito:groups']?.includes('CanToggleCreditCheck');
  const canEditBillingDay = payload['cognito:groups']?.includes('CanEditBillingDay');
  const canToggleCHDiscount = payload['cognito:groups']?.includes('CanToggleCHDiscount');
  const canToggleInventoryDiscount = payload['cognito:groups']?.includes('CanToggleInventoryDiscount');
  const canEditPolicyMPD = payload['cognito:groups']?.includes('CanEditPolicyMPD');
  const canDelayPaymentOrRefund = payload['cognito:groups']?.includes('CanDelayPaymentOrRefund');
  const canManageBillingHolds = payload['cognito:groups']?.includes('CanManageBillingHolds');
  const canSendPaperDocs = payload['cognito:groups']?.includes('CanSendPaperDocs');
  const canEditFeeCheckbox = payload['cognito:groups']?.includes('CanEditFeeCheckbox');
  const canOverrideGaragingAddress = payload['cognito:groups']?.includes('CanOverrideGaragingAddress');
  const canSwapApplicants = payload['cognito:groups']?.includes('CanSwapApplicants');
  const canViewCarrierInitiatedCancels = payload['cognito:groups']?.includes('CanViewCarrierInitiatedCancels');
  const canSellAndService =
    payload['cognito:groups']?.includes('ExternalSales') &&
    parseCustomAttribute(payload['custom:allowed_states'])?.length > 0;
  const canCancelReasonNACP = payload['cognito:groups']?.includes('CanCancelReasonNACP');
  const canEditUneditablePropertyDetailsFields = payload['cognito:groups']?.includes(
    'CanEditUneditablePropertyDetails'
  );
  const canResetOnboardingTasks = payload['cognito:groups']?.includes('CanResetOnboardingTasks');
  const canViewUWCancelCodes = payload['cognito:groups']?.includes('CanViewUWCancelCodes');
  const canToggleIsAgencyPolicy = payload['cognito:groups']?.includes('CanToggleIsAgencyPolicy');
  const canSkipEmailConfirmationSplashPage = payload['cognito:groups']?.includes('CanSkipEmailConfirmationPage');
  const canRefundStripePayment = payload['cognito:groups']?.includes('CanRefundStripePayment');
  const canRescindOrReinstateGSNICPolicy = payload['cognito:groups']?.includes('CanRescindOrReinstateGSNICPolicy');
  const canRemoveFinalPaymentMethod = payload['cognito:groups']?.includes('CanRemoveFinalPaymentMethod');
  const canBypassDriversLicenseCheck = payload['cognito:groups']?.includes('CanBypassDriversLicenseCheck');
  const canEditCoverageA = payload['cognito:groups']?.includes('CanEditCoverageA');

  // can view both offers and policies but will not allow editing
  const viewOnly = payload['cognito:groups']?.includes('ViewOnly');

  // Users that are not licensed in any states and therefore should only have a populated list of unlicensed states alongside the Unlicensed cognito group
  const isUnlicensed = hasUnlicensedGroup && Boolean(parsedUnlicensedStates?.length && !parsedAllowedStates.length);

  // Users that are not licensed in any states will have access to editing only unlicensed fields on offers/policies.
  // Alongside the Unlicensed group, they should also have populated list of unlicensed states.
  // The form field component logic takes care of disabling the licensed fields for these users.
  const canEditPolicies = (isTeamLeader || isSales || isService || isUnlicensed) && !viewOnly;
  const canEditOffers = (isTeamLeader || isSales || isService || isUnlicensed || hasCanQuoteGroup) && !viewOnly;
  const canViewOffers = viewOnly || canEditOffers;
  const canViewPolicies = viewOnly || hasCanViewPolicyGroup || canEditPolicies;

  // tools permissions:
  const canViewTools = payload['cognito:groups']?.includes('CanViewTools');
  const canViewAffinityTools = payload['cognito:groups']?.includes('toolsCanViewAffinityTools');
  const canViewStaffDashboard = payload['cognito:groups']?.includes('toolsStaffDashboard');
  const canResetStaffUserPassword = payload['cognito:groups']?.includes('toolsStaffDashboardResetPassword');
  const canDisableStaffUser = payload['cognito:groups']?.includes('toolsStaffDashboardDisableUser');
  const canCreateStripeCharge = payload['cognito:groups']?.includes('toolsCreateStripeCharge');
  const canCreateChargedeskInvoice = payload['cognito:groups']?.includes('toolsCreateChargedeskInvoice');
  const canCreateChargedeskRefundInvoice = payload['cognito:groups']?.includes('toolsChargedeskRefundInvoice');
  const canBulkDisableStaffUsers = payload['cognito:groups']?.includes('toolsStaffUsersBulkDisable');
  const canRunAddressesThroughCherre = payload['cognito:groups']?.includes('toolsRunAddressesThroughCherre');
  const canRegeneratePolicyForm = payload['cognito:groups']?.includes('toolsRegeneratePolicyForm');
  const canRegeneratePolicyCancelForm = payload['cognito:groups']?.includes('toolsRegeneratePolicyCancelForm');
  const canUpdateChargedeskRecord = payload['cognito:groups']?.includes('toolsChargedeskUpdateRecord');
  const canUpdateChargedeskRecordPolicyOrBillingId = payload['cognito:groups']?.includes('toolsCDUpdateRecordLimited');
  const canGenerateRenewal = payload['cognito:groups']?.includes('toolsCanGenerateRenewal');
  const canRunRenewalUnderwriting = payload['cognito:groups']?.includes('toolsCanRunRenewalUnderwriting');
  const canUpdateStaffUser = payload['cognito:groups']?.includes('toolsStaffDashboardUpdateUser');
  const canViewEditGroups = payload['cognito:groups']?.includes('toolsViewEditUserGroups');
  const canUpdatePolicyRep = payload['cognito:groups']?.includes('toolsUpdatePolicyRep');
  const canAddPermissionGroups = payload['cognito:groups']?.includes('toolsAddPermissionGroups');
  const canCreateStaffUser = payload['cognito:groups']?.includes('toolsCreateStaffUser');
  const canCreateFrontTicket = payload['cognito:groups']?.includes('toolsCreateFrontTicket');
  const canViewStateRateChangeDashboard = payload['cognito:groups']?.includes('toolsStateRateChangeDashboard');
  const canImportPoliciesToSnapsheet = payload['cognito:groups']?.includes('toolsImportPoliciesToSnapsheet');
  const canImportPoliciesToIvans = payload['cognito:groups']?.includes('toolsImportPoliciesToIvans');
  const canGetIvansFormattedPolicy = payload['cognito:groups']?.includes('toolsGetIvansFormattedPolicy');
  const canAddCatTagsToClaims = payload['cognito:groups']?.includes('toolsCanAddCatTagsToClaims');
  const canBulkBillBees = payload['cognito:groups']?.includes('toolsCanBulkBillBees');
  const canBulkBillPilot = payload['cognito:groups']?.includes('toolsCanBulkBillPilot');
  const canBulkBillMedlogix = payload['cognito:groups']?.includes('toolsCanBulkBillMedlogix');
  const canBulkBillEnterprise = payload['cognito:groups']?.includes('toolsCanBulkBillEnterprise');
  const canBulkBillCopartRecoveries = payload['cognito:groups']?.includes('toolsCanBulkBillCopartRecoveries');
  const canBulkBillFleetSubrogation = payload['cognito:groups']?.includes('toolsCanBulkBillFleetSubrogation');
  const canRunTechTasks = payload['cognito:groups']?.includes('toolsCanRunTechTasks');
  const canRunBillHonk = payload['cognito:groups']?.includes('toolsCanRunBillHonk');
  const canRunBillHover = payload['cognito:groups']?.includes('toolsCanRunBillHover');
  const canCopyPolicy = payload['cognito:groups']?.includes('toolsCanCopyPolicy');
  const canSetMemberAffinity = payload['cognito:groups']?.includes('toolsCanSetMemberAffinity');
  const canEnableStaffUser = payload['cognito:groups']?.includes('toolsCanEnableStaffUser');
  const canResetPasskeys = payload['cognito:groups']?.includes('toolsCanResetPasskeys');
  const canPullDrivingHistoryFromCSV = payload['cognito:groups']?.includes('toolsCanPullDrivingHistory');
  const canSetHasAutoPolicy = payload['cognito:groups']?.includes('toolsCanSetHasAutoPolicy');
  const canUpdateBillingId = payload['cognito:groups']?.includes('toolsCanUpdateBillingId');
  const canOverrideUmbrellaLimit = payload['cognito:groups']?.includes('toolsCanOverrideUmbrellaLimit');
  const canRunCorrectiveActions = payload['cognito:groups']?.includes('toolsCanRunCorrective');
  const canChangeOfferAffinity = payload['cognito:groups']?.includes('toolsCanChangeOfferAffinity');
  const canBulkBillAccuserve = payload['cognito:groups']?.includes('toolsCanBulkBillAccuserve');
  const canBulkBillHancock = payload['cognito:groups']?.includes('toolsCanBulkBillHancock');
  const canAddViolationToOffer = payload['cognito:groups']?.includes('toolsAddAutoViolationsOffer');
  const canUpdateStaffUsername = payload['cognito:groups']?.includes('toolsCanUpdateStaffUsername');

  return {
    // allows quoting, editing, and viewing offers
    [CognitoPermissionGroups.canQuote]: (isTeamLeader || isSales || isService || hasCanQuoteGroup) && !viewOnly,

    // allows purchasing offers/binding
    [CognitoPermissionGroups.canBind]: (isTeamLeader || isSales || isService) && !viewOnly,

    // allows viewing the checkout route but not necessarily binding
    [CognitoPermissionGroups.canViewCheckoutPage]:
      (isTeamLeader || isSales || isService || hasCanQuoteGroup) && !viewOnly,

    [CognitoPermissionGroups.canViewPolicies]: canViewPolicies,
    [CognitoPermissionGroups.canViewOffers]: canViewOffers,

    // if opening policy/offer for state not licensed for, the code should only allow unlicensed actions
    // which is determined via the permissions prop and useFormFieldPermissionHelpers used in FormField
    [CognitoPermissionGroups.canEditPolicies]: canEditPolicies,
    [CognitoPermissionGroups.canEditOffers]: canEditOffers,
    [CognitoPermissionGroups.canEdit]: canEditPolicies || canEditOffers,

    // allows viewing offers, policies, and customer, but does not allow editing, checkout, or modifying the data of any kind
    [CognitoPermissionGroups.viewOnly]: viewOnly,
    [CognitoPermissionGroups.isTeamLeader]: isTeamLeader && !viewOnly,
    [CognitoPermissionGroups.isSales]: isSales,
    [CognitoPermissionGroups.isService]: isService && !viewOnly,
    [CognitoPermissionGroups.isAgency]: isAgency,
    [CognitoPermissionGroups.isInternalAgent]: !isAgency,
    [CognitoPermissionGroups.isUnlicensed]: isUnlicensed,
    [CognitoPermissionGroups.canReinstate]: canReinstate,
    [CognitoPermissionGroups.canRescindCancellation]: canRescindCancellation,
    [CognitoPermissionGroups.canSeeFullOfferDetails]: canSeeFullOfferDetails,
    [CognitoPermissionGroups.isInternalSales]: isInternalSales && !viewOnly,
    [CognitoPermissionGroups.isExternalSales]: isExternalSales && !viewOnly,
    [CognitoPermissionGroups.canScrubIncidents]: canScrubIncidents,
    [CognitoPermissionGroups.canModifyAffinityAndLeadSource]: canModifyAffinityAndLeadSource,
    [CognitoPermissionGroups.canBackDate]: canBackDate && !isAgency,
    [CognitoPermissionGroups.canModifyBillingId]: canModifyBillingId && !isAgency,
    [CognitoPermissionGroups.canViewClarionDoorData]: canViewClarionDoorData,
    [CognitoPermissionGroups.canAddCarsManually]: canAddCarsManually && !isAgency,
    [CognitoPermissionGroups.canAutoRenew]: canAutoRenew && !isAgency,
    [CognitoPermissionGroups.canAddHoldCards]: canAddHoldCards,
    [CognitoPermissionGroups.canToggleEmployeeDiscount]: canToggleEmployeeDiscount && !isAgency,
    [CognitoPermissionGroups.canChangeExclusions]: canChangeExclusions,
    [CognitoPermissionGroups.canClearUDRs]: canClearUDRs,
    [CognitoPermissionGroups.canEditHomeAddress]: canEditHomeAddress,
    [CognitoPermissionGroups.canEditEffectiveDate]: canEditEffectiveDate,
    [CognitoPermissionGroups.canFlagAsSuspicious]: canFlagAsSuspicious,
    [CognitoPermissionGroups.canManuallyChargeFee]: canManuallyChargeFee,
    [CognitoPermissionGroups.canQuoteQuinstreet]: canQuoteQuinstreet,
    [CognitoPermissionGroups.canClearUWFormRejections]: canClearUWFormRejections,
    [CognitoPermissionGroups.canUploadDocuments]: (isInternalSales || isService || isTeamLeader) && !viewOnly,
    [CognitoPermissionGroups.canManuallyChargePolicy]: canManuallyChargePolicy,
    [CognitoPermissionGroups.canRewritePolicy]: canRewritePolicy,
    [CognitoPermissionGroups.canRemoveExcludedDriver]: canRemoveExcludedDriver,
    [CognitoPermissionGroups.canCancelReasonCNIP]: canCancelReasonCNIP,
    [CognitoPermissionGroups.canCancelReasonCNMS]: canCancelReasonCNMS,
    [CognitoPermissionGroups.canCancelReasonCNPY_X]: canCancelReasonCNPY_X,
    [CognitoPermissionGroups.canRejectPIP]: canRejectPIP,
    [CognitoPermissionGroups.showAgencyPortal]: showAgencyPortal || isAgency,
    [CognitoPermissionGroups.canViewQlikReports]: canViewQlikReports,
    [CognitoPermissionGroups.canDownloadAnonDocs]: canDownloadAnonDocs,
    [CognitoPermissionGroups.canReinstateCNPY]: canReinstateCNPY,
    [CognitoPermissionGroups.canToggleCreditCheck]: canToggleCreditCheck,
    [CognitoPermissionGroups.canEditBillingDay]: canEditBillingDay,
    [CognitoPermissionGroups.canToggleCHDiscount]: canToggleCHDiscount,
    [CognitoPermissionGroups.canToggleInventoryDiscount]: canToggleInventoryDiscount,
    [CognitoPermissionGroups.canEditPolicyMPD]: canEditPolicyMPD,
    [CognitoPermissionGroups.canDelayPaymentOrRefund]: canDelayPaymentOrRefund,
    [CognitoPermissionGroups.canManageBillingHolds]: canManageBillingHolds,
    [CognitoPermissionGroups.canSendPaperDocs]: canSendPaperDocs,
    [CognitoPermissionGroups.canEditFeeCheckbox]: canEditFeeCheckbox,
    [CognitoPermissionGroups.canOverrideGaragingAddress]: canOverrideGaragingAddress,
    [CognitoPermissionGroups.canSwapApplicants]: canSwapApplicants,
    [CognitoPermissionGroups.canViewCarrierInitiatedCancels]: canViewCarrierInitiatedCancels,
    [CognitoPermissionGroups.canSellAndService]: canSellAndService,
    [CognitoPermissionGroups.canCancelReasonNACP]: canCancelReasonNACP,
    [CognitoPermissionGroups.canEditUneditablePropertyDetailsFields]: canEditUneditablePropertyDetailsFields,
    [CognitoPermissionGroups.canResetOnboardingTasks]: canResetOnboardingTasks,
    [CognitoPermissionGroups.canViewUWCancelCodes]: canViewUWCancelCodes,
    [CognitoPermissionGroups.canToggleIsAgencyPolicy]: canToggleIsAgencyPolicy,
    [CognitoPermissionGroups.canSkipEmailConfirmationSplashPage]: canSkipEmailConfirmationSplashPage,
    [CognitoPermissionGroups.canRefundStripePayment]: canRefundStripePayment,
    [CognitoPermissionGroups.canViewTools]: canViewTools,
    [CognitoPermissionGroups.canViewAffinityTools]: canViewAffinityTools,
    [CognitoPermissionGroups.canViewStaffDashboard]: canViewStaffDashboard,
    [CognitoPermissionGroups.canResetStaffUserPassword]: canResetStaffUserPassword,
    [CognitoPermissionGroups.canCreateStripeCharge]: canCreateStripeCharge,
    [CognitoPermissionGroups.canCreateChargedeskInvoice]: canCreateChargedeskInvoice,
    [CognitoPermissionGroups.canBulkDisableStaffUsers]: canBulkDisableStaffUsers,
    [CognitoPermissionGroups.canCreateChargedeskRefundInvoice]: canCreateChargedeskRefundInvoice,
    [CognitoPermissionGroups.canRegeneratePolicyForm]: canRegeneratePolicyForm,
    [CognitoPermissionGroups.canUpdateChargedeskRecord]: canUpdateChargedeskRecord,
    [CognitoPermissionGroups.canUpdateChargedeskRecordPolicyOrBillingId]: canUpdateChargedeskRecordPolicyOrBillingId,
    [CognitoPermissionGroups.canGenerateRenewal]: canGenerateRenewal,
    [CognitoPermissionGroups.canRunRenewalUnderwriting]: canRunRenewalUnderwriting,
    [CognitoPermissionGroups.canUpdateStaffUser]: canUpdateStaffUser,
    [CognitoPermissionGroups.canDisableStaffUser]: canDisableStaffUser,
    [CognitoPermissionGroups.canViewEditGroups]: canViewEditGroups,
    [CognitoPermissionGroups.canRunAddressesThroughCherre]: canRunAddressesThroughCherre,
    [CognitoPermissionGroups.canUpdatePolicyRep]: canUpdatePolicyRep,
    [CognitoPermissionGroups.canAddPermissionGroupsToStaffUsers]: canAddPermissionGroups,
    [CognitoPermissionGroups.canCreateStaffUser]: canCreateStaffUser,
    [CognitoPermissionGroups.canCreateFrontTicket]: canCreateFrontTicket,
    [CognitoPermissionGroups.canViewStateRateChangeDashboard]: canViewStateRateChangeDashboard,
    [CognitoPermissionGroups.canImportPoliciesToSnapsheet]: canImportPoliciesToSnapsheet,
    [CognitoPermissionGroups.canImportPoliciesToIvans]: canImportPoliciesToIvans,
    [CognitoPermissionGroups.canGetIvansFormattedPolicy]: canGetIvansFormattedPolicy,
    [CognitoPermissionGroups.canRegeneratePolicyCancelForm]: canRegeneratePolicyCancelForm,
    [CognitoPermissionGroups.canAddCatTagsToClaims]: canAddCatTagsToClaims,
    [CognitoPermissionGroups.canBulkBillCopartRecoveries]: canBulkBillCopartRecoveries,
    [CognitoPermissionGroups.canBulkBillBees]: canBulkBillBees,
    [CognitoPermissionGroups.canBulkBillPilot]: canBulkBillPilot,
    [CognitoPermissionGroups.canBulkBillMedlogix]: canBulkBillMedlogix,
    [CognitoPermissionGroups.canBulkBillEnterprise]: canBulkBillEnterprise,
    [CognitoPermissionGroups.canBulkBillFleetSubrogation]: canBulkBillFleetSubrogation,
    [CognitoPermissionGroups.canRunTechTasks]: canRunTechTasks,
    [CognitoPermissionGroups.canRescindOrReinstateGSNICPolicy]: canRescindOrReinstateGSNICPolicy,
    [CognitoPermissionGroups.canRemoveFinalPaymentMethod]: canRemoveFinalPaymentMethod,
    [CognitoPermissionGroups.canCopyPolicy]: canCopyPolicy,
    [CognitoPermissionGroups.canSetMemberAffinity]: canSetMemberAffinity,
    [CognitoPermissionGroups.canEnableStaffUser]: canEnableStaffUser,
    [CognitoPermissionGroups.canResetPasskeys]: canResetPasskeys,
    [CognitoPermissionGroups.canRunTechTasks]: canRunTechTasks,
    [CognitoPermissionGroups.canRemoveFinalPaymentMethod]: canRemoveFinalPaymentMethod,
    [CognitoPermissionGroups.canCopyPolicy]: canCopyPolicy,
    [CognitoPermissionGroups.canSetMemberAffinity]: canSetMemberAffinity,
    [CognitoPermissionGroups.canEnableStaffUser]: canEnableStaffUser,
    [CognitoPermissionGroups.canResetPasskeys]: canResetPasskeys,
    [CognitoPermissionGroups.canRunBillHonk]: canRunBillHonk,
    [CognitoPermissionGroups.canRunBillHover]: canRunBillHover,
    [CognitoPermissionGroups.canPullDrivingHistoryFromCSV]: canPullDrivingHistoryFromCSV,
    [CognitoPermissionGroups.canSetHasAutoPolicy]: canSetHasAutoPolicy,
    [CognitoPermissionGroups.canUpdateBillingId]: canUpdateBillingId,
    [CognitoPermissionGroups.canOverrideUmbrellaLimit]: canOverrideUmbrellaLimit,
    [CognitoPermissionGroups.canBypassDriversLicenseCheck]: canBypassDriversLicenseCheck,
    [CognitoPermissionGroups.canRunCorrectiveActions]: canRunCorrectiveActions,
    [CognitoPermissionGroups.canChangeOfferAffinity]: canChangeOfferAffinity,
    [CognitoPermissionGroups.canBulkBillHancock]: canBulkBillHancock,
    [CognitoPermissionGroups.canAddViolationToOffer]: canAddViolationToOffer,
    [CognitoPermissionGroups.canBulkBillAccuserve]: canBulkBillAccuserve,
    [CognitoPermissionGroups.canUpdateStaffUsername]: canUpdateStaffUsername,
    [CognitoPermissionGroups.canEditCoverageA]: canEditCoverageA
  };
}

const defaultState = {
  user: null,
  permissionGroups: [],
  isLoggedIn: false,
  logout: signOut,
  updateEmail: async (emailAddress) =>
    AwsAuth.currentAuthenticatedUser().then((user) => {
      AwsAuth.updateUserAttributes(user, { email: emailAddress });
    }),
  loading: true,
  userPreferences: null
};

const GET_USER_PREFERENCES = gql`
  query {
    getUserPreferences {
      username
      affinityGroups
      meiliSearchKey
      meiliSearchHost
      searchEngine
    }
  }
`;

function getAllowedStates({ parsedAllowedStates }) {
  if (parsedAllowedStates?.length) {
    return parsedAllowedStates;
  }
  return [];
}

class Auth extends React.Component {
  static contextType = MobXProviderContext; // This sets `this.context` to be the MobX store.
  static propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.object.isRequired,
    toast: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    Hub.listen('auth', async (capsule) => {
      const { channel, payload } = capsule;
      if (channel === 'auth') {
        const { event, data } = payload;
        if (event === 'signIn') {
          const branchSession = await this.setAuthStateFromSession(
            data.signInUserSession.idToken.payload,
            data.signInUserSession.idToken.jwtToken
          );
          initializeFrontChatAndIdentify(branchSession);
          identifyUserInUpscope(branchSession.user);

          if (awsConfig.trackingEnabled) {
            if (branchSession?.user?.username) {
              window.sessionStorage.setItem('branchSessionUsername', `staff-${branchSession?.user?.username}`);
            }

            // Segment analytics tracking
            await identify({
              username: branchSession.user.username,
              email: branchSession.user.email,
              isAgency: branchSession.isAgency,
              canSellAndService: branchSession.canSellAndService,
              firstName: branchSession.user.firstName || null,
              lastName: branchSession.user.lastName || null,
              agencyRole: branchSession.user.agencyRole || null
            });
            track('Staff Sign In', {
              username: branchSession.user.username,
              email: branchSession.user.email
            });
          }

          const deepLink = oAuthStorage.getDeepLink();
          if (deepLink) {
            window.location.href = deepLink;
          }

          if (event === 'signOut') {
            window.location.reload();
          }

          if (event === 'signIn_failure' && awsConfig.trackingEnabled) {
            track('Unsuccesful Staff Sign In', {
              reason: `${capsule.payload.message} ${capsule.payload.data.message}`
            });
          }
        }
      }
    }).bind(this);
    this.state = defaultState;
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const session = await AwsAuth.currentSession();
      const branchSession = await this.setAuthStateFromSession(
        session.idToken.payload,
        session.getIdToken().getJwtToken()
      );
      initializeFrontChatAndIdentify(branchSession);

      // Segment analytics tracking
      await identify({
        username: branchSession.user.username,
        email: branchSession.user.email,
        isAgency: branchSession.isAgency,
        canSellAndService: branchSession.canSellAndService,
        firstName: branchSession.user.firstName || null,
        lastName: branchSession.user.lastName || null,
        agencyRole: branchSession.user.agencyRole || null
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error getting session from AwsAuth: ', e);
      this.setState({ ...defaultState, loading: false });
    }
  }

  async getUserPreferences() {
    const { client } = this.props;
    try {
      const {
        data: { getUserPreferences }
      } = await client.query({ query: GET_USER_PREFERENCES });

      return {
        affinityGroups: getUserPreferences?.affinityGroups,
        meiliSearchKey: getUserPreferences?.meiliSearchKey,
        meiliSearchHost: getUserPreferences?.meiliSearchHost,
        searchEngine: getUserPreferences?.searchEngine
      };
    } catch (e) {
      console.warn('Try deploying dynamoDB/staff-appsync-be to have a valid user preferences response.');
      return { affinityGroups: undefined, meiliSearchKey: undefined };
    }
  }

  async setAuthStateFromSession(payload, token) {
    const userPreferences = await this.getUserPreferences();
    if (this.context.store) {
      this.context.store.userPreferences.setUserPreferences(userPreferences);
    }

    const user = getUserFromPayload(payload);
    const permissionGroups = permissions(payload);
    const parsedAllowedStates = parseCustomAttribute(payload['custom:allowed_states']);
    const parsedUnlicensedStates = parseCustomAttribute(payload['custom:unlicensed_states']);

    const state = {
      user,
      isLoggedIn: true,
      ...permissionGroups,
      token,
      loading: false,
      allowedStates: getAllowedStates({ parsedAllowedStates, permissionGroups }),
      unlicensedStates: parsedUnlicensedStates?.length ? parsedUnlicensedStates : [],
      userPreferences
    };

    this.setState(state);
    return state;
  }

  render() {
    const { children } = this.props;

    return (
      <DescopeProvider projectId={process.env.REACT_APP_STAFF_DESCOPE_PROJECT_ID}>
        <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>
      </DescopeProvider>
    );
  }
}

export default withToast(Auth);
