import React from 'react';
import PropTypes from 'prop-types';
import MUITooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import infoIcon from '../../assets/svg/info.svg';
import infoIconLight from '../../assets/svg/info-light.svg';
import infoIconSmall from '../../assets/svg/info-small.svg';
import useStyles from './tooltip.styles';

export function Tooltip({
  text,
  className,
  placement,
  light,
  label,
  onClick,
  size,
  labelNextToText,
  height,
  smallIcon,
  clickIcon,
  iconSize: icSize,
  ...props
}) {
  const styleClasses = useStyles({ size, height });
  const iconSize = icSize || 'smallIcon';
  return (
    <MUITooltip classes={{ tooltip: styleClasses.tooltip }} title={text} placement={placement} interactive arrow>
      {!label ? (
        <span className={styleClasses.iconContainer} onClick={onClick}>
          {smallIcon ? (
            <img
              className={classNames(className, styleClasses[iconSize])}
              src={smallIcon.src || infoIconSmall}
              alt={text}
            />
          ) : (
            <img
              className={classNames(className, styleClasses.info)}
              src={light ? infoIconLight : infoIcon}
              alt={text}
            />
          )}
        </span>
      ) : (
        <div {...props} className={styleClasses.labelWrapper}>
          <span
            className={classNames(className, styleClasses.desktopLabel, {
              [styleClasses.labelNextToText]: labelNextToText
            })}
            onClick={onClick}
          >
            {label}
            {clickIcon && <img src={clickIcon} alt={text} />}
          </span>
        </div>
      )}
    </MUITooltip>
  );
}

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  light: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  labelNextToText: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large'])
};

Tooltip.defaultProps = {
  className: '',
  label: '',
  onClick: () => {},
  placement: 'right-start',
  light: false,
  labelNextToText: false,
  size: 'large'
};

export default Tooltip;
