/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  lookupsJson,
  getOptions,
  mineSubsidenceOptional,
  waterBackupNameByState,
  equipmentBreakdownServiceLineCoveragesStates,
  EBSLstateDate
} from '@ourbranch/lookups';
import { isAfter, isSameDay } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { getShowMSALE } from 'offer/helpers/get-coverage';
import { tooltipHoverTexts, lockedValuesTooltipTexts } from 'core/helpers/constants';
import { useStore } from 'core/store';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { awsDateToDateObject } from 'core/helpers/formatters';
import { localToUtcTime } from 'customer/components/policy/dates';
import useStyles from '../coverages.styles';
import useExtendedRoofCoverage from '../../hooks/use-extended-roof-coverage';
import RoofSurfaceCoverage from './roof-surface-coverage/roof-surface-coverage-modal';
import icViewMore from 'common/assets/icViewMore_12px.svg';

function OtherCoverages({ id, home, enforceMS, state, coverageA, fromPolicy }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const optionalMS =
    mineSubsidenceOptional[state] &&
    (mineSubsidenceOptional[state][home.county.toUpperCase()] || mineSubsidenceOptional[state].all);

  const showMSALE = getShowMSALE(state);

  const waterBackupLabel = (waterBackupNameByState && waterBackupNameByState[state]) || 'Water Backup';

  const rseCoverageFieldOptions = useExtendedRoofCoverage(home, state, fromPolicy);
  const { rsLabel, lockedValue, forcedStandardRSCoverage, forcedExtendedRSCoverage } = rseCoverageFieldOptions;

  useEffect(() => {
    if (lockedValue === true) {
      if (forcedStandardRSCoverage) {
        setFieldValue(`${id}.coverageRSExtended`, false);
      }
      if (forcedExtendedRSCoverage) {
        setFieldValue(`${id}.coverageRSExtended`, true);
      }
    }
  }, [forcedStandardRSCoverage, forcedExtendedRSCoverage]);

  const { account, offer: offerStore } = useStore();
  const policyStore = account?.policies?.policy;
  const offerWaterBackup =
    policyStore?.policy?.offer?.quote?.offerings?.offerWaterBackup ||
    offerStore?.offer?.quote?.offerings?.offerWaterBackup;

  let canAddEquipmentBreakdownServiceLineCoverages = equipmentBreakdownServiceLineCoveragesStates.includes(state);
  const rateControlDate = awsDateToDateObject(offerStore?.offer?.quote?.global.rateControlDate);
  const StateDate = new Date(EBSLstateDate[state]);

  if (fromPolicy) {
    const { updatedDateTime } = policyStore?.policy.versionHistory.find(({ version }) => version === 1);
    let createdDate = localToUtcTime(updatedDateTime, state);
    if (state === 'TX') {
      // exception in TX to look at effectiveDate because of early publishing
      createdDate = localToUtcTime(policyStore?.policy.effectiveDate, state);
    }
    canAddEquipmentBreakdownServiceLineCoverages =
      canAddEquipmentBreakdownServiceLineCoverages &&
      policyStore?.policy?.isBix &&
      (isAfter(createdDate, StateDate) || isSameDay(StateDate, createdDate));
  } else {
    // new offer
    canAddEquipmentBreakdownServiceLineCoverages =
      canAddEquipmentBreakdownServiceLineCoverages &&
      offerStore?.offer?.quote?.isBix &&
      (isAfter(rateControlDate, StateDate) || isSameDay(StateDate, rateControlDate));
  }

  const [openRoofSurfaceModal, setOpenRoofSurfaceModal] = useState(false);
  const learnMoreRoofSurfaceTooltip = {
    label: 'Learn more',
    onHoverText: 'Click to learn more',
    clickIcon: icViewMore,
    onClick: () => setOpenRoofSurfaceModal(true)
  };

  return (
    <Grid container item spacing={5} className={classes.coverageContainer} alignItems="flex-start">
      <Grid item xs={12}>
        <Label key="lblTop" type="formSubTitle" className={classes.cardSubSectionDivider}>
          Other Coverages
        </Label>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Family Liability"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.familyLiability }}
        >
          <Field
            id={`${id}.coverageX`}
            name={`${id}.coverageX`}
            type="select"
            mode="dark"
            // how do we change this over to lookupsJson?
            options={getOptions('coverageX', state)}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Guest Medical Limit"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.guestMedicalLimit }}
        >
          <Field
            id={`${id}.coverageY`}
            name={`${id}.coverageY`}
            type="select"
            mode="dark"
            // how do we change this over to lookupsJson?
            options={getOptions('guestMedicalLimit', state)}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Loss Assessments"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.lossAssessments }}
        >
          <Field
            id={`${id}.coverageG`}
            name={`${id}.coverageG`}
            type="select"
            mode="dark"
            options={getOptions('coverageG', state)}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Building Code Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.buildingCodeCoverage }}
        >
          <Field
            id={`${id}.coverageBCPctOfA`}
            name={`${id}.coverageBCPctOfA`}
            type="select"
            mode="dark"
            // how do we change this over to lookupsJson?
            options={getOptions('coverageBCPctOfA', state).map((option) => ({
              id: option.id,
              value: `${option.value}% (${Number((option.value / 100) * coverageA).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })})`
            }))}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label={waterBackupLabel}
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.waterBackup }}
        >
          <Field
            id={`${id}.coverageWaterBackup`}
            name={`${id}.coverageWaterBackup`}
            type="select"
            mode="dark"
            className="has-custom-error"
            disabled={!offerWaterBackup}
            options={getOptions('waterBackupCoverageOptions', state)}
          />
          {!offerWaterBackup && (
            <Label type="formLabel" className={classes.errorMessage}>
              Cannot offer water backup due to history of water backup claims
            </Label>
          )}
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        {lockedValue ? (
          <Field
            id={`${id}.coverageRSExtended`}
            name={`${id}.coverageRSExtended`}
            label="Roof Surfaces"
            type="value"
            mode="locked"
            value={rsLabel}
            lockedValue={{
              text: lockedValuesTooltipTexts.roofSurfaceStandardCoverage
            }}
            showTooltip={learnMoreRoofSurfaceTooltip}
            className={classes.lockedRoofSurface}
          />
        ) : (
          <LabelTooltip label="Roof Surfaces" tooltip={learnMoreRoofSurfaceTooltip}>
            <Field
              id={`${id}.coverageRSExtended`}
              name={`${id}.coverageRSExtended`}
              type="select"
              mode="dark"
              options={getOptions('roofSurfaceOptions')}
            />
          </LabelTooltip>
        )}
      </Grid>

      {(optionalMS || enforceMS) && (
        <>
          <Field
            id={`${id}.coverageMS`}
            name={`${id}.coverageMS`}
            type="checkbox"
            label="Mine Subsidence"
            mode="dark"
            xs={6}
            disabled={!!enforceMS}
          />
          {showMSALE && !values[id].coverageMS && (
            <Field
              id={`${id}.coverageMSALE`}
              name={`${id}.coverageMSALE`}
              type="select"
              label="Mine Subsidence Additional Expense Coverage"
              mode="dark"
              xs={6}
              disabled
              options={lookupsJson.mineSubsidenceALECoverageOptions}
            />
          )}
          {showMSALE && values[id].coverageMS && (
            <Field
              id={`${id}.coverageMSALE`}
              name={`${id}.coverageMSALE`}
              type="select"
              label="Mine Subsidence Additional Expense Coverage"
              mode="dark"
              xs={6}
              options={lookupsJson.mineSubsidenceALECoverageOptions}
            />
          )}
        </>
      )}

      {canAddEquipmentBreakdownServiceLineCoverages && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageEBSL`}
            name={`${id}.coverageEBSL`}
            type="checkbox"
            label="Equipment Breakdown & Service Line"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageEBSL}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      <RoofSurfaceCoverage open={openRoofSurfaceModal} onClose={() => setOpenRoofSurfaceModal(false)} />
    </Grid>
  );
}

OtherCoverages.propTypes = {
  id: PropTypes.string.isRequired,
  home: PropTypes.object.isRequired,
  enforceMS: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  coverageA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fromPolicy: PropTypes.bool.isRequired
};

export default observer(OtherCoverages);
