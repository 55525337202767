/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { isAfter } from 'date-fns';
import { localToUtcTime } from '@ourbranch/date-helpers';
import {
  coverageRSExtendedForcedStates,
  coverageRSForcedStates,
  extendedCoverageRSExpirationByAgeStateExclusions,
  noShowRoofSurfacesExtendedUnderXYearsStates,
  coverageRSForcedAtXYearsStates,
  extendedCoverageRSByRoofMaterialStateExclusions,
  extendedCoverageRSLimits
} from '@ourbranch/lookups';

import { awsDateToDateObject } from 'core/helpers/formatters';
import { useStore } from 'core/store';

const useExtendedRoofCoverage = (home, state, fromPolicy) => {
  const { account, offer: offerStore } = useStore();

  if (!home) {
    return null;
  }

  const policyStore = account?.policies?.policy;

  const roofAgeLimit =
    !extendedCoverageRSByRoofMaterialStateExclusions.includes(state) && extendedCoverageRSLimits[home.roofType]
      ? extendedCoverageRSLimits[home.roofType]
      : coverageRSForcedAtXYearsStates[state] || 15;

  let dateToCheckAgainst = new Date();

  if (fromPolicy) {
    dateToCheckAgainst = new Date(localToUtcTime(policyStore?.policy.effectiveDate, state));
  } else {
    dateToCheckAgainst = awsDateToDateObject(offerStore?.offer?.quote?.global.rateControlDate);
  }

  const roofAge = dateToCheckAgainst.getFullYear() - home.roofYear;

  let forcedRSECoverage = false;
  let shouldCheckExpirationByAge = !extendedCoverageRSExpirationByAgeStateExclusions[state];

  if (state === 'PA') {
    let createdDate = localToUtcTime(new Date(), state);
    if (fromPolicy) {
      const { inceptionDate } = policyStore?.policy;
      const { updatedDateTime } = policyStore?.policy.versionHistory.find(({ version }) => version === 1);
      createdDate = localToUtcTime(inceptionDate || updatedDateTime, state);
      forcedRSECoverage = forcedRSECoverage && isAfter(createdDate, new Date('2024-04-01'));
    } else {
      shouldCheckExpirationByAge = isAfter(createdDate, new Date('2024-04-01'));
    }
  }

  const forcedStandardRSCoverage =
    coverageRSForcedStates[state] || (shouldCheckExpirationByAge && roofAge > roofAgeLimit);

  const forcedExtendedRSCoverage =
    coverageRSExtendedForcedStates[state] ||
    (noShowRoofSurfacesExtendedUnderXYearsStates[state] &&
      roofAge <= noShowRoofSurfacesExtendedUnderXYearsStates[state]);

  return {
    lockedValue: forcedStandardRSCoverage || forcedExtendedRSCoverage || false,
    rsLabel: forcedExtendedRSCoverage ? 'Extended (repair/replace)' : 'Standard (payment schedule)',
    forcedStandardRSCoverage,
    forcedExtendedRSCoverage
  };
};

export default useExtendedRoofCoverage;
