import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { ActionButton } from 'core/components/action-button';
import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import useSession from 'core/hooks/use-session';
import { NotificationCard } from 'core/components/notification-card';
import AgencySupportBannerExtended from 'core/components/agency-support-banner-extended';
import useStyles from './roof-surface-coverage-modal.styles';

const RoofSurfaceCoverage = ({ open, onClose }) => {
  const classes = useStyles();
  const { isAgency } = useSession();

  const underwritingSentence = isAgency ? (
    <>
      You can find a copy of the Roof Surfaces payment schedule in the{' '}
      <a className={classes.link} href="/portal/underwriting" rel="noreferrer noopener" target="_blank">
        Underwriting section
      </a>{' '}
      of your agency portal.
    </>
  ) : (
    'External agents may find a copy of the payment schedule in the Underwriting section of their agency portal.'
  );
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle className={classes.title}>
        <Label type="titleSecondary">Roof surfaces coverages</Label>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Label type="infoInnerTitleBold" className={classes.text}>
          We offer two types of coverage for Roof surfaces:
        </Label>

        <p className={classes.paragraph}>
          <Label type="infoInnerTitleBold">Standard (Payment Schedule)</Label>
          <br />
          If the roof is damaged by wind or hail and the member has an eligible claim, it will be{' '}
          <strong>
            paid using a payment schedule determined by roof age and material and not by replacement cost.
          </strong>{' '}
          The older the roof, the less Branch will pay of the cost of the roof replacement. {underwritingSentence}
        </p>

        <p className={classes.paragraph}>
          <Label type="infoInnerTitleBold">Extended(Repair/Replacement)</Label>
          <br />
          If the roof is damaged by wind or hail and the member has an eligible claim, it will be{' '}
          <strong>paid using the replacement cost with no application of a payment schedule.</strong> This means that
          the claim is paid up to the amount of money needed to repair or replace the roof at today's material prices.
        </p>

        <NotificationCard className={classes.notification} iconSize="icon16" type="infoGreen">
          <Label className={classes.notificationCardText}>
            We may or may not offer both of these coverage options depending on the State and age of the roof.
          </Label>
        </NotificationCard>
      </DialogContent>
      {isAgency && <AgencySupportBannerExtended content="If you have questions, please contact us." />}
    </Dialog>
  );
};

RoofSurfaceCoverage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RoofSurfaceCoverage;
