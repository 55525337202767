import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24,
    borderRadius: theme.sizes.roundCorners
  },
  additionalCoverages: {
    backgroundColor: theme.colors.card_background_secondary,
    paddingRight: 16,
    paddingLeft: 16,
    borderRadius: theme.sizes.roundCorners
  },
  coverageAmount: {
    marginTop: 6
  },
  coverageLabel: {
    paddingTop: 4
  },
  umbrellaLabel: {
    color: theme.colorPalette.beige_10
  },
  umbrellaContent: {
    marginTop: 28
  },
  coverageContainer: {
    paddingTop: '24px !important' // override the grid spacing
  },
  errorMessage: {
    color: '#f44336',
    textTransform: 'none',
    fontSize: 10
  },
  noPaddingTop: {
    marginTop: 0,
    position: 'relative',
    top: -10
  },
  link: {
    color: theme.colorPalette.orange_10,
    textDecoration: 'underline !important'
  },
  sppLinkButton: {
    composes: '$link',
    fontSize: 12,
    padding: theme.spacing(0),
    marginBottom: theme.spacing(0.4)
  },
  button: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colorPalette.white_10,
    minWidth: 150
  },
  buttonLink: {
    color: theme.colorPalette.white_10
  },
  purchasedContainer: {
    backgroundColor: theme.colorPalette.white_10_op_15,
    padding: 16,
    borderRadius: 6
  },
  outlineContainer: {
    border: `2px solid ${theme.colorPalette.green_15}`,
    boxShadow: 'none',
    paddingLeft: theme.spacing(4)
  },
  infoIcon: {
    height: 16,
    width: 16
  },
  notificationCard: {
    padding: theme.spacing(10)
  },
  notificationCardText: {
    fontSize: 12,
    padding: theme.spacing(6, 1, 6, 2)
  },
  cardSubSectionDivider: {
    borderTop: `1px solid ${theme.colorPalette.white_30_op_30}`,
    paddingTop: theme.spacing(4)
  },
  cardSubSectionTitle: {
    paddingBottom: theme.spacing(4),
    fontSize: 14,
    color: theme.colorPalette.beige_75
  },
  lockedRoofSurface: {
    marginTop: theme.spacing(4)
  }
}));
