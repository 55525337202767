import React, { useMemo, useRef, useEffect, useContext, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { lookupsJson, policyType } from '@ourbranch/lookups';
import { getSnapshot } from 'mobx-state-tree';
import { Form, useFormikContext, FieldArray } from 'formik';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import AddButton from 'core/components/add-button';
import RemoveButton from 'core/components/remove-button';
import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { connectedHomeTooltipHoverTexts as TooltipHoverTexts } from 'core/helpers/constants';
import withDatePicker from 'core/components/with-date-picker';
import useAvailablePolicies from 'quote/hooks/use-available-policies';
import AddressAutocomplete from 'core/components/address-autocomplete';
import { Collapsible } from '../collapsible';
import { ErrorAlert, getAlerts } from '../quote-errors';
import { applicantCodes, priorAddressCodes, propDetailsCodes, sections } from '../quote-forms/quote-forms.constants';
import useStyles from './applicant-details.styles';

const ApplicantDetails = ({
  setShowPriorAddressCallout,
  setShowPropertyDetailsCallout,
  sectionsToShow,
  setSectionsToShow,
  showAllSectionsClicked,
  setShowAllSectionsClicked
}) => {
  const {
    affinityLookups,
    quote: { errors: quoteErrors }
  } = useStore();

  // Common function to check if Prior Address or Property Detail are visible.
  const checkVisibility = (source) => source.every((section) => sectionsToShow.includes(section));

  // Get or set if prior address is visible.
  const [priorAddressVisible, setPriorAddressVisible] = useState(checkVisibility(priorAddressCodes));

  // Get or set if property details is visible.
  const [propDetailsVisible, setPropDetailsVisible] = useState(checkVisibility(propDetailsCodes));

  const classes = useStyles();
  const { setFieldValue, setFieldTouched, values, isSubmitting } = useFormikContext();

  const { isAgency, user, allowedStates, canModifyAffinityAndLeadSource, isInternalSales } = useContext(AuthContext);

  const selectablePolicyTypes = useAvailablePolicies(values.state);

  const hasCondo = [policyType.Condo, policyType.CABundle].includes(values.policyType);

  const { leadSourceOptions } = useMemo(() => {
    return {
      leadSourceOptions: lookupsJson.leadSources
    };
  }, [allowedStates]);

  const affinityData = affinityLookups.suggestedAffinities;

  const affinityAllowsNewCustomerSignUp = useMemo(
    () => Boolean(values.affinity && values.affinity !== 'None' && affinityData?.signUpThroughBranch),
    [values.affinity, affinityData]
  );

  const [showAllSectionsVisible, setShowAllSectionsVisible] = useState(true);

  const alerts = getAlerts({
    errors: getSnapshot(quoteErrors),
    codesArray: applicantCodes,
    policyType: values.policyType
  });

  useEffect(() => {
    if (typeof isInternalSales == 'boolean') {
      setShowAllSectionsVisible(isInternalSales);
    }
  }, [isInternalSales]);

  useEffect(() => {
    if (isAgency) {
      setFieldValue('leadSource', 'Agency', false);
      setFieldValue('affinity', user.affinityCode, false);
    }
    setFieldValue('rep', user.username, false);
  }, [user, isAgency, setFieldValue]);

  const applicantErrors = useRef(null);

  useEffect(() => {
    if (alerts.length && applicantErrors.current && isSubmitting) {
      applicantErrors.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [alerts, isSubmitting]);

  useEffect(() => {
    if (values.isNewConstruction) {
      setShowPriorAddressCallout(true);
      setShowPropertyDetailsCallout(true);

      const isPriorAddressVisible = checkVisibility(priorAddressCodes);
      const isPropDetailsVisible = checkVisibility(propDetailsCodes);
      let sectionsToShowCloned = [...sectionsToShow];

      // If new construction is checked and Prior address section is hidden, it will be included in SectionsToShow variable.
      if (!isPriorAddressVisible) {
        sectionsToShowCloned = [...sectionsToShowCloned, ...priorAddressCodes];
      }

      // If new construction is checked and Property details section is hidden, it will be included in SectionsToShow variable.
      if (!isPropDetailsVisible) {
        sectionsToShowCloned = [...sectionsToShowCloned, ...propDetailsCodes];
      }

      setPriorAddressVisible(isPriorAddressVisible);
      setPropDetailsVisible(isPropDetailsVisible);
      setSectionsToShow(sectionsToShowCloned);
    }

    if (!values.isNewConstruction) {
      setShowPriorAddressCallout(false);
      setShowPropertyDetailsCallout(false);

      let sectionsToShowCloned = [...sectionsToShow];
      // If new construction is unchecked, Prior address will be hidden and removed for SectionsToShow variable.
      if (!priorAddressVisible) {
        sectionsToShowCloned = sectionsToShowCloned.filter((section) => !priorAddressCodes.includes(section));
      }

      // If new construction is unchecked, Property details will be hidden and removed for SectionsToShow variable.
      if (!propDetailsVisible) {
        sectionsToShowCloned = sectionsToShowCloned.filter((section) => !propDetailsCodes.includes(section));
      }

      setSectionsToShow(sectionsToShowCloned);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.isNewConstruction]);

  useEffect(() => {
    if (values.isNewPurchase) {
      setShowPriorAddressCallout(true);

      const isPriorAddressVisible = checkVisibility(priorAddressCodes);

      let sectionsToShowCloned = [...sectionsToShow];

      // If new purchase is checked and Prior address section is hidden, it will be included in SectionsToShow variable.
      if (!isPriorAddressVisible) {
        sectionsToShowCloned = [...sectionsToShowCloned, ...priorAddressCodes];
      }

      setPriorAddressVisible(isPriorAddressVisible);
      setSectionsToShow(sectionsToShowCloned);
    }

    if (!values.isNewPurchase) {
      setShowPriorAddressCallout(false);

      let sectionsToShowCloned = [...sectionsToShow];
      // If new purchase is unchecked, Prior address will be hidden and removed for SectionsToShow variable.
      if (!priorAddressVisible) {
        sectionsToShowCloned = sectionsToShowCloned.filter((section) => !priorAddressCodes.includes(section));
      }

      setSectionsToShow(sectionsToShowCloned);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.isNewPurchase]);

  const showAllSections = useCallback(() => {
    setShowAllSectionsClicked(true);
    setSectionsToShow(sections);
  }, [setSectionsToShow, setShowAllSectionsClicked]);

  return (
    <Form>
      {alerts.length > 0 && <ErrorAlert errors={alerts} ref={applicantErrors} />}
      <Collapsible initialOpen title="Applicant Details*">
        <Field xs={6} type="string" id="firstName" name="firstName" mode="light" label="First Name*" />
        <Field xs={6} type="string" id="lastName" name="lastName" mode="light" label="Last Name*" />
        <AddressAutocomplete id="address" name="address" xs={12} mode="light" label="Address*" />
        <Field xs={12} type="string" id="unit" name="unit" mode="light" label="Floor, unit, building, etc." />
        <Field xs={4} type="string" id="city" name="city" mode="light" label="City*" />
        <Field xs={4} type="state" id="state" name="state" mode="light" label="State*" />
        <Field xs={4} type="string" id="zip" name="zip" mode="light" label="Zip Code*" />
        <Field
          xs={6}
          type="select"
          id="policyType"
          name="policyType"
          mode="light"
          label="Policy Type"
          disabled={!values.state}
          options={selectablePolicyTypes}
          fast={false}
        />

        <Grid item className={classes.noUnitNumber} xs={6}>
          <Field
            type="checkbox"
            id="noUnitNumber"
            name="noUnitNumber"
            mode="light"
            label="Condo does not have an apt/unit number"
            disabled={!hasCondo}
            fast={false}
            item
          />
        </Grid>
        <Grid item className={classes.left} xs={6}>
          <Field
            type="checkbox"
            id="isNewConstruction"
            name="isNewConstruction"
            mode="light"
            label="New Construction?"
            disabled={values.isNewPurchase}
            fast={false}
            item
          />
        </Grid>
        <Grid item className={classes.left} xs={6}>
          <Field
            type="checkbox"
            id="isNewPurchase"
            name="isNewPurchase"
            mode="light"
            label="New Purchase?"
            disabled={values.isNewConstruction}
            fast={false}
            xs={6}
          />
        </Grid>
        <Field xs={8} type="string" id="email" name="email" mode="light" label="Email" />
        <Field xs={4} type="string" id="phone" name="phone" mode="light" label="Phone Number" />
        <FieldArray
          name="additionalPhoneNumbers"
          render={(arrayHelpers) => (
            <Grid container alignItems="center" spacing={2} justifyContent="flex-start">
              {values.additionalPhoneNumbers?.length !== 0 &&
                values.additionalPhoneNumbers?.map((_, index) => (
                  <Grid container key={`addtl-phone-${index}`} className={classes.secondaryPhoneContainer} spacing={2}>
                    <Field
                      mode="light"
                      name={`additionalPhoneNumbers.${index}.phoneNumber`}
                      id={`additionalPhoneNumbers.${index}.phoneNumber`}
                      label="Secondary Phone"
                      type="string"
                      xs={4}
                    />

                    <Field
                      mode="light"
                      name={`additionalPhoneNumbers.${index}.note`}
                      id={`additionalPhoneNumbers.${index}.note`}
                      label="Description"
                      type="string"
                      xs={7}
                    />
                    <RemoveButton
                      xs={2}
                      mode="big"
                      onClick={() => {
                        arrayHelpers.remove(index);
                        setFieldTouched('additionalPhoneNumbers');
                      }}
                    />
                    <Field
                      mode="light"
                      name={`additionalPhoneNumbers.${index}.canText`}
                      id={`additionalPhoneNumbers.${index}.canText`}
                      label="Does the potential member agree to receiving texts at this number?"
                      type="checkbox"
                      className={classes.textCheckbox}
                    />
                  </Grid>
                ))}
              <AddButton
                type="full"
                mode="xl"
                onClick={() => {
                  arrayHelpers.push({ phoneNumber: '', note: '', canText: false });
                  setFieldTouched('additionalPhoneNumbers');
                }}
                label="Add an additional phone number"
              />
            </Grid>
          )}
        />
        {canModifyAffinityAndLeadSource && (
          <>
            <Field
              xs={4}
              type="select"
              id="leadSource"
              name="leadSource"
              mode="light"
              label="Lead Source"
              options={leadSourceOptions}
            />
            <Field xs={4} type="affinity" id="affinity" mode="light" name="affinity" label="Affinity Partner" />
            <Field
              xs={4}
              type="checkbox"
              id="signUpForNewMonitoringService"
              name="signUpForNewMonitoringService"
              mode="light"
              label="Sign up for new monitoring service?"
              tooltipText={TooltipHoverTexts.homeSecurityPartnerCustomerType}
              tooltipLabel="More Info"
              className={classes.left}
              disabled={!affinityAllowsNewCustomerSignUp}
              fast={false}
            />
          </>
        )}
        {showAllSectionsVisible && !showAllSectionsClicked && (
          <Grid item xs={12} className={classes.checkboxContainer}>
            <Label type="action" onClick={showAllSections}>
              Show all fields
            </Label>
          </Grid>
        )}
      </Collapsible>
    </Form>
  );
};

ApplicantDetails.propTypes = {
  setShowPriorAddressCallout: PropTypes.func.isRequired,
  setShowPropertyDetailsCallout: PropTypes.func.isRequired
};

export default withDatePicker(ApplicantDetails);
