import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    marginBottom: theme.spacing(2)
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 600
  },
  value: {
    minHeight: 20,
    marginTop: theme.spacing(1)
  },
  'dark-label': {
    composes: '$label',
    color: theme.colors.light_text,
    opacity: 0.6
  },
  'dark-value': {
    composes: '$value',
    color: theme.colors.light_text
  },
  'locked-label': {
    color: theme.colorPalette.beige_60
  },
  'locked-value': {
    color: theme.colors.white
  },
  valueFieldTooltip: {
    position: 'relative',
    top: theme.spacing(-1),
    margin: theme.spacing(0, 1)
  }
});

export default makeStyles(styles);
