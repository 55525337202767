import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Tooltip } from 'core/components/tooltip';
import { Label } from '../label';
import useStyles from './value-field.styles';
import icLocked from 'core/assets/svg/icLocked.svg';

function ValueField({ label, value, width, style, mode, formatter, className, showTooltip, lockedValue, ...props }) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, className)} data-cy="value-field" style={style} {...props}>
      <Label>
        {label && (
          <Label type="infoLabel" className={classes[mode ? `${mode}-label` : 'label']}>
            {label}
          </Label>
        )}
        {showTooltip && (
          <Tooltip
            className={classes.valueFieldTooltip}
            text={showTooltip.onHoverText}
            label={showTooltip.label || 'More Info'}
            clickIcon={showTooltip.clickIcon}
            onClick={showTooltip.onClick}
          />
        )}
      </Label>
      <Label id="value" type="infoValue" className={classes[mode ? `${mode}-value` : 'value']}>
        {formatter ? formatter(value) : value}
        {lockedValue && (
          <Tooltip text={lockedValue.text} placement="top" smallIcon={{ src: icLocked }} iconSize="icon16" />
        )}
      </Label>
    </div>
  );
}

ValueField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  mode: PropTypes.oneOf([null, 'light', 'dark', 'big-font-dark']),
  formatter: PropTypes.func,
  className: PropTypes.string,
  showTooltip: PropTypes.string
};

ValueField.defaultProps = {
  label: '',
  value: null,
  width: undefined,
  style: {},
  mode: null,
  formatter: null,
  className: '',
  showTooltip: ''
};

export default ValueField;
