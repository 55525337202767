import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core';
import { Tooltip } from '../tooltip';
import useStyles from './label-tooltip.styles';
import classNames from 'classnames';

const LabelTooltip = memo(
  ({
    labelClassName,
    containerClassName,
    tooltipClassName,
    label,
    labelNextToText,
    tooltip,
    children,
    placement,
    mode,
    disabled,
    onClick
  }) => {
    const classes = useStyles();
    const LabelComponent = (
      <div className={classNames(!onClick && !!label && classes.header, labelClassName)}>
        <Label
          type={mode === 'light' ? 'formLabelLight' : 'formLabel'}
          className={classNames(classes.label, {
            [classes.disabled]: disabled,
            [classes.labelNextToText]: labelNextToText
          })}
        >
          {label}
          {tooltip.onHoverText && (
            <Tooltip
              className={tooltipClassName}
              text={tooltip.onHoverText || 'Click Icon To See Info'}
              label={tooltip.label}
              placement={placement}
              onClick={onClick || tooltip.onClick}
              clickIcon={tooltip.clickIcon}
            />
          )}
        </Label>
      </div>
    );

    return (
      // only using the 'container' className if the LabelToolTip has children i.e. is used as a container
      <>
        {children && (
          <div className={classNames(classes.container, containerClassName)}>
            {LabelComponent}
            {children}
          </div>
        )}
        {!children && LabelComponent}
      </>
    );
  }
);

LabelTooltip.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltip: PropTypes.object,
  children: PropTypes.node,
  placement: PropTypes.string,
  mode: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
  labelNextToText: PropTypes.bool
};

LabelTooltip.defaultProps = {
  tooltip: { onHoverText: '' },
  labelClassName: '',
  containerClassName: '',
  tooltipClassName: '',
  children: null,
  label: '',
  placement: 'top',
  mode: 'dark',
  disabled: false,
  labelNextToText: false
};

export default LabelTooltip;
